/*Universal Property*/
.btn:focus-visible {
    outline: none;
}

.btn:focus {
    outline: none;
}

.btn:active:focus {
    outline: none;
}
/*End Universal Property*/

.logoCollapsed {
    display: none;
}

.mainAdminWrapper a:focus {
    outline: none;
}

.mycustomSidebar {
    position: fixed !important;
}

    .mycustomSidebar .ps-menuitem-root.TopMiamLogo {
        height: 70px;
        background: #fff;
    }

        .mycustomSidebar .ps-menuitem-root.TopMiamLogo a {
            background: #fff;
            padding-top: 18px;
            padding-left: 35px;
        }

            .mycustomSidebar .ps-menuitem-root.TopMiamLogo a:hover {
                background: #fff;
            }

.logoNotCollapsed img {
    width: auto;
    height: 60px;
}

.mycustomSidebar {
    background: #414048;
    border-color: transparent !important;
    height: 100vh;
    transition: width,left,right,200ms !important;
}

    .mycustomSidebar .ps-sidebar-container {
        background: transparent;
    }

    .mycustomSidebar .ps-menuitem-root a {
        color: #fff;
        background: #414048;
    }

    .mycustomSidebar .ps-sidebar-container ul .ps-menuitem-root .ps-menu-button.active {
        color: #4d79c7;
    }

.ps-active {
    color: #4d79c7;
}

.mycustomSidebar .ps-menuitem-root a:hover {
    background: #414048;
}

.mycustomSidebar .ps-submenu-content.ps-open {
    background: #414048;
}

.mycustomSidebar .ps-menu-button:hover {
    background-color: transparent;
}
/*.mycustomSidebar .ps-menuitem-root.ps-submenu-root.ps-open a{
	color: #4d79c7;
}*/
.HeaderTag {
    box-shadow: none !important;
    background-color: #fff !important;
}

.HeaderContent .MuiButtonBase-root svg {
    color: #878a99;
}

.HeaderTag .HeaderContent {
    min-height: 70px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.IconProfileSetup {
    display: flex;
    width: 250px;
    justify-content: right;
}

.wholeCompoundRight {
    width: 100%;
    margin-left: 250px;
    position: relative;
    transition: all 0.2s ease-out;
    overflow-x: hidden;
}

.ps-collapsed.mycustomSidebar .logoNotCollapsed {
    display: none;
}

.ps-collapsed.mycustomSidebar .logoCollapsed {
    display: flex;
    justify-content: center;
}

    .ps-collapsed.mycustomSidebar .logoCollapsed img {
        width: auto;
        height: 40px;
    }

.ps-collapsed.mycustomSidebar .ps-menuitem-root.TopMiamLogo a {
    padding: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wholeCompoundRight.fullwidth {
    margin-left: 80px;
}

.RightContent {
    position: fixed;
    top: 0;
    z-index: 4;
    right: 0;
    left: 0px;
    margin-left: 250px;
    transition: all 0.2s ease-out;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.13);
}

.ps-collapsed.mycustomSidebar {
    z-index: 2;
}

.wholeCompoundRight.fullwidth .RightContent {
    margin-left: 80px;
}

.headingTop h1 {
    margin: 0;
    color: #fff;
    font-size: 28px;
}

.headingTop {
    background: #b6b6af;
    min-height: 320px;
    padding: 20px;
    position: absolute;
    width: 100%;
}

.mainAdminWrapper {
    display: flex;
    background: #d7d7d3;
    max-width: 1920px;
    margin: auto;
}

.rightContetBlock {
    position: relative;
    padding-top: 70px;
    height: calc(100vh - 60px);
    overflow-y: scroll;
}

.fixtopContentBg {
    position: relative;
    width: 100%;
    padding: 20px;
}

.card-Content {
    background: #ededeb url("../images/card-bg.png") no-repeat;
    padding: 15px;
    border: 1px solid #b6b6af;
    background-size: cover;
    border-radius: 4px;
    min-height: 350px;
}

.Content-body .nav-tabs > li.active > a, .Content-body .nav-tabs > li.active > a:focus, .Content-body .nav-tabs > li.active > a:hover {
    color: #000;
    cursor: default;
    background-color: #e9e5dd;
    border: 1px solid #b6b6af;
    border-bottom-color: transparent;
}

.Content-body .nav-tabs > li > a {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px 8px 0 0;
    padding: 8px 15px;
}

    .Content-body .nav-tabs > li > a:hover {
        border-color: #b6b6af;
        border-bottom: transparent;
    }

.Content-body .nav > li > a:hover {
    text-decoration: none;
    background-color: #e9e5dd;
}

.Content-body .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}

.Content-body .nav-tabs > li {
    margin-bottom: 0;
}

.Content-body .nav-tabs {
    border-bottom: 1px solid #b6b6af;
}

.Content-body {
    color: #000;
}

.InsertForms .form-group label {
    font-weight: 600;
    margin-bottom: 10px;
}

.card-heading.topsubheading {
    margin-top: 0;
}

.custom-dollor td {
    position: relative;
}

    .custom-dollor td::before {
        content: "$";
        position: absolute;
        width: 30px;
        left: 14px;
        font-size: 14px;
        top: 15px;
        color: #555;
    }

    .custom-dollor td.remove-dollar::before {
        display: none;
    }

    .custom-dollor td input {
        padding-left: 15px;
    }

.InsertForms {
    padding-top: 25px;
}

.card-heading {
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 10px;
}

    .card-heading.topHeadings {
        font-size: 24px;
        font-weight: 600;
    }

.blank150 {
    height: 60px;
}

.btn.btn-primary.save-button {
    background-color: #4d79c7;
    border-color: #4d79c7;
    min-width: 100px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 8px;
    padding: 8px 12px;
}

    .btn.btn-primary.save-button:focus, .btn.btn-primary.save-button:focus-visible {
        outline: none;
    }

.footerDivision p {
    color: #98a6ad;
    font-size: 14px;
    margin: 0;
}

.footerDivision {
    background: #fff;
    padding: 20px 5px;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.13);
}

.profileHeadSide {
    background: #f3f3f9;
    width: 150px;
    height: 70px;
    display: flex !important;
    justify-content: left;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
}

.HeaderContent .profileHeadSide .MuiButtonBase-root svg {
    width: 32px;
    height: 32px;
}

.prfileDivIcon {
    padding: 0 !important;
    margin-right: 8px !important;
}

    .prfileDivIcon:hover {
        background-color: transparent !important;
    }

.Profile-head span {
    display: block;
    color: #414048;
    font-size: 14px;
}

    .Profile-head span.bussinessName {
        font-size: 12px;
        color: #878a99;
    }

.OpenBOx .MuiPaper-root.MuiMenu-paper {
    position: absolute !important;
    left: auto !important;
    right: 25px !important;
    top: 70px !important;
    width: 150px !important;
}

    .OpenBOx .MuiPaper-root.MuiMenu-paper ul li {
        height: 100%;
        font-family: 'Libre Franklin';
        font-size: 14px;
        padding: 5px 10px;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        line-height: 18px;
    }

    .OpenBOx .MuiPaper-root.MuiMenu-paper ul li {
        height: 100%;
        font-family: 'Libre Franklin';
        font-size: 14px;
        padding: 5px 10px;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        line-height: 18px;
    }

.Content-body .form-group .form-control {
    border: 1px solid #b6b6af;
    border-radius: 0;
}

.form-group.Product-Label-Front .chcekcontrol.form-control {
    margin-top: 0;
    position: absolute;
    right: 90px;
    width: 20px;
    border: none;
    box-shadow: none;
}

.form-group.Product-Label-Front aside {
    margin-top: 0 !important;
}

.Content-body .form-group .form-control.error, .Content-body .form-group .error {
    border: 1px solid red !important;
}

.dropzone.custom-input-drop.error {
    border: 2px dashed red;
}

.cardSubtext {
    font-weight: normal;
    margin: 0 0 20px;
}

.custom-input-drop {
    min-height: 150px;
    border: 2px dashed #e9ebec;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 15px;
    cursor: pointer;
}

.mainThumbnail {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}

.thumbanilOptions .mainThumbnail div img {
    width: 100px !important;
    height: 100% !important;
    object-fit: contain;
}

.deleteThis {
    position: absolute;
    right: 5px;
    color: red;
    font-size: 10px;
    font-weight: 400;
    background: #000000ab;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4px;
    cursor: pointer;
}

.LogosUplaods .card-heading {
    margin-top: 0;
}

.LogosUplaods {
    border-bottom: 1px solid #b6b6af;
    padding-bottom: 15px;
    margin: 28px 0 15px;
}

    .LogosUplaods:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

.btn.btn-secondary.save-button {
    background: #6c757d;
    border-color: #6c757d;
    color: #fff;
    min-width: 100px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 8px;
    padding: 8px 12px;
}

.formBtns.threeSubSets .btn {
    margin-right: 10px;
}

.btn.btn-secondary.save-button:focus, .btn.btn-secondary.save-button:focus-visible {
    outline: none;
}

.rdt_TableCell:last-child {
    width: 100%;
}

.rdt_TableCol {
    font-weight: 700;
    font-size: 14px;
}

.btn.btn-secondary {
    background: #6c6c6c;
    color: #ffff;
    border-color: #6c6c6c;
}

.rdt_TableCell .btn.btn-secondary {
    background: #6c6c6c;
    color: #ffff;
    border-color: #6c6c6c;
    min-width: 101px;
}

.rdt_TableCell .btn.btn-warning {
    min-width: 101px;
}

.rdt_TableCell .btn {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
}

.tbaShowcase .rdt_TableCell .btn {
    width: 101px;
}

.btn.btn-secondary:hover {
    background: #494747;
}

.css-1u9des2-indicatorSeparator {
    background-color: transparent !important;
}

.test-memnu .sub-menu-item {
    font-size: 14px;
}

    .test-memnu .sub-menu-item a {
        color: #121212;
        text-decoration: none;
    }

.test-memnu a {
    color: #121212;
    text-decoration: none;
}

.selectTodesign > div {
    border: 1px solid #b6b6af;
    min-height: 34px !important;
    border-radius: 0;
}

    .selectTodesign > div > div {
        padding-top: 0;
        padding-bottom: 0;
    }

        .selectTodesign > div > div:last-child > div {
            padding: 6px 8px;
        }

.italic-placeholder {
    font-size: 14px;
    font-weight: 500;
}

.form-control:focus {
    box-shadow: none;
}
/*7/7/23*/

.listing-pack {
    display: flex;
}

    .listing-pack .form-control {
        height: 37.6px;
    }

    .listing-pack .ml-2 {
        margin-left: 30px;
    }

.priceCalculator .form-control {
    max-width: 400px;
    width: 100%;
    border-radius: 0;
}

.priceCalculator .card-heading {
    color: #000;
}

.priceCalculator .cardSubtext {
    color: #000;
    font-size: 14px !important;
}

.priceCalculator .form-group {
    margin-bottom: 5px;
}

.amount-field {
    display: flex;
    align-items: center;
}

.priceCalculator .me-2 {
    margin-right: 5px;
}

.cardactionBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .cardactionBtns.buttonWidth a {
        width: 100%;
        text-transform: uppercase;
        font-weight: 600;
    }

    .cardactionBtns a {
        width: 50%;
        text-transform: uppercase;
        font-weight: 600;
    }

.Content-body .mb-20 {
    margin-bottom: 30px;
}

.MuiCardMedia-media.product-image {
    object-fit: contain !important;
}
/*Profile Page*/
.ProfilePic .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
}

.ReactCrop__child-wrapper img {
    max-height: 380px !important;
    height: auto;
    width: auto;
    max-width: 100% !important;
}

.addProfilePopup.editpicture .modal-content {
    width: 600px;
    height: 550px;
    text-align: center;
}

.checkbtnsse {
    margin: 25px 0 0;
}

.blank25 {
    height: 25px;
}

.profileImages img {
    width: 110px;
    background: #fff;
    padding: 5px;
    height: 110px;
    border-radius: 50%;
}

.profileImages.editoptions {
    position: relative;
}

    .profileImages.editoptions input.upload-input {
        opacity: 0;
        position: absolute;
        top: 10px;
        z-index: 2;
    }

.profileDescription h2 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
}

.profileImages.editoptions .upload-input {
    position: absolute;
    top: 0;
    visibility: hidden;
}

.profileDescription h3 {
    margin-top: 8px;
    font-size: 18px;
    color: #444;
}

.col-md-2.ImgPart {
    width: 140px;
}

.comPundEntites {
    display: block;
    margin: 0 0 15px;
}

    .comPundEntites label {
        float: left;
        width: 30%;
        margin: 0;
        font-weight: 600;
    }

.ValueEntites {
    display: inline-block;
    color: #4b4b4b;
    width: 70%;
}

.editUserProfile {
    text-align: right;
    margin: 15px 0 0;
}

.iconText i {
    margin-right: 15px;
}

.profileIcons {
    margin: 0 0 10px;
}
/*End Profile Page*/
/*Product Details Page*/
.previewImages {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.preview-pic {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.Content-body.dataTableSearchBox.fix-heigts {
    height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 15px;
}

.previewImages .tab-content img {
    width: 100%;
    animation-name: opacity;
    animation-duration: .3s;
    height: 350px;
    object-fit: contain;
    background: #fff;
}

.preview-thumbnail.nav-tabs {
    border: none;
    margin-top: 15px;
}

    .preview-thumbnail.nav-tabs li {
        width: 18%;
        margin-right: 2.5%;
        background: #fff;
    }

        .preview-thumbnail.nav-tabs li img {
            max-width: 100%;
            display: block;
            height: 85px;
            object-fit: cover;
            margin: auto;
        }

        .preview-thumbnail.nav-tabs li:last-of-type {
            margin-right: 0;
        }

.Content-body .preview-thumbnail.nav-tabs li a, .Content-body .preview-thumbnail.nav-tabs li a:hover {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
}

.product-titleBeer {
    margin-top: 0;
    font-size: 29px;
    text-transform: uppercase;
    font-weight: 700;
}

.producTBrandsm {
    margin-top: 0;
}

.priceCurrent span {
    color: #337ab7;
    margin-left: 5px;
}

.priceCurrent {
    font-weight: 700;
    margin: 32px 0 0px;
}

.vote {
    font-size: 14px;
    font-style: italic;
    margin: 0 0 35px;
}

.addtocartBTns .btn {
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 15px;
}

.priceCurrent.smallIten {
    margin: 0 0 10px;
    font-size: 16px;
}

    .priceCurrent.smallIten label {
        min-width: 100px;
        margin: 0;
    }

.prodcutDesTabs {
    margin: 35px 0 0;
}

    .prodcutDesTabs .tab-content {
        padding: 15px 0 0;
    }

.Content-body.productListingPage .col-md-3.mb-20 .product-list-component {
    width: 100%;
    max-width: 100%;
}

.row.mutliplePrices {
    display: flex;
    align-items: center;
}

.col-md-3.mb-20 .product-list-component .ProductListCardContent {
    min-height: 192px;
    padding-bottom: 5px;
}

.ProductListCardContent .MuiTypography-root.MuiTypography-h6.MuiTypography-gutterBottom {
    margin-bottom: 0;
}

.Content-body.productListingPage .row {
    margin-bottom: 3px;
}
/*End Product Details Page*/
/*Team Page Design*/
.teamMember.rounded-bg {
    background: #fff;
    box-shadow: 0px 15px 15px -15px #4c4c4c;
    text-align: center;
    padding: 65px 15px 20px;
    margin-bottom: 30px;
    border: 1px solid #cbcbcb;
    height: auto;
    max-width: 100%;
    margin: auto auto 30px;
}

    .teamMember.rounded-bg:hover {
        box-shadow: 0 0 10px #4c4c4cb8;
    }

    .teamMember.rounded-bg img {
        background: #fff;
        border: 1px solid #b6b6af;
        height: 130px;
        width: 130px;
        border-radius: 50%;
        margin: auto;
        object-fit: cover;
        margin-bottom: 35px;
    }

.preview-img-icons {
    position: relative;
    width: 43px;
}

    .preview-img-icons img {
        width: 100%;
        max-width: 58px;
        height: 58px;
        object-fit: cover;
    }

.teamMember.rounded-bg h3 {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.teamPageBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0 0;
    gap: 8px;
}

    .teamPageBtns .btn {
        text-transform: uppercase;
        font-weight: 600;
        width: 50%;
    }

.addProfilePopup .btn {
    font-weight: 600;
    text-transform: uppercase;
}

    .addProfilePopup .btn:focus, .addProfilePopup .btn:focus-visible {
        outline: none;
    }

.addProfilePopup .modal-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.addProfilePopup .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
}

.addProfilePopup .form-group .form-control {
    border-radius: 0;
    box-shadow: none;
}
/*End Team Page Design*/
/*Cart Icon Header*/
.iconcart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70px;
    flex-direction: row;
}

.iconsetHeader {
    width: auto;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.iconcart svg {
    color: #0062ff;
    width: 26px;
    height: 26px;
}

.iconNumberCart {
    position: absolute;
    top: -14px;
    right: 2px;
    font-size: 9px;
    background: #404c52;
    color: #fff;
    text-align: center;
    font-weight: 400;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    line-height: 17px;
}

.emptyCartdiv img {
    max-width: 130px;
    margin: auto;
}

.emptyCartdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
}

    .emptyCartdiv a {
        text-transform: uppercase;
        font-weight: 600;
        margin: 15px 0 0;
    }

.noiteminYOurcart {
    font-size: 20px;
}
/*End Cart Icon Header*/
/*Like Icon*/
.product-list-component {
    position: relative;
}

.stage {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    background: #fff;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    box-shadow: 0 0 5px #00000059;
}

    .stage i {
        position: relative;
        top: 1px;
    }

    .stage .fa.fa-heart {
        color: #1285c9;
    }

/*End Like Icon*/
/*Search Icon On Top*/
.searchOntopPage .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
}

.searchBox {
    max-width: 235px;
    float: right;
    width: 100%;
}

    .searchBox .form-control {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid transparent;
        padding-left: 0;
    }

    .searchBox .input-group-btn .btn {
        border-radius: 0;
        border-color: transparent;
    }

    .searchBox .input-group-btn i {
        color: #388ff6;
    }

    .searchBox .input-group-btn .btn:hover,
    .searchBox .input-group-btn .btn:focus,
    .searchBox .input-group-btn .btn:active {
        background-color: #fff;
        box-shadow: none;
    }

    .searchBox .form-control::placeholder {
        color: #ccc;
        font-family: 'Libre Franklin';
        font-size: 16px;
    }
/*End Search Icon Top*/
/*card-details datalist start */
pl-0 {
    font-size: 0.35rem;
}

.pl-1 {
    font-size: 22px;
    color: #4d79c7;
}

p.pl-1.mb-0 {
    margin: 0;
}

h6.subbrnadNames {
    margin: 5px 0;
}

.productThumimf {
    background: #ededeb;
}

    .productThumimf img {
        width: 100%;
        height: 100px;
        object-fit: contain;
    }

.list-items {
    background-color: #fff;
    padding: 15px;
    min-height: 200px;
    box-shadow: 0 15px 15px -15px #4c4c4c;
    border: 1px solid #cbcbcb;
}

.card-details {
    margin: 10px 5px;
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 0 5px;
}

.cartHeadings h4, .cartHeadings h5 {
    margin: 0;
    color: #000;
}

.cartHeadings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.heading {
    border-bottom: 1px solid #b6b6af;
}

.cartItem {
    --bs-gutter-x: 1.5rem;
    padding: 15px;
    border-bottom: 1px solid #b6b6af;
}

.w-100 {
    max-width: 100%;
    height: 100px;
}

.btn-spaces {
    border-top: 1px solid #b6b6af;
    padding: 10px 0 0 0;
}

.formBtns.btn-spaces.placeore {
    border-top: none;
    padding: 0;
}

.totla-Text {
    line-height: 15px;
}

.row.TotlaPyes .total_numberText {
    font-size: 18px;
    font-weight: 700;
}

.row.TotlaPyes {
    padding: 15px 0 0;
}

    .row.TotlaPyes span {
        display: block;
    }

.trash-btn {
    color: red;
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
}

.footer-list {
    padding: 10px 0;
}

.card-Content.cartPages .list-items {
    box-shadow: none;
}

.card-body.bottomTwo {
    margin: 20px 0 0;
}

.radio.inline-block {
    float: left;
    display: inline-block;
    width: 60px;
}

.radio-Content {
    float: left;
    width: auto;
}

    .radio-Content h4 {
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .radio-Content p {
        margin: 0 0 5px;
    }

        .radio-Content p span {
            font-weight: 600;
        }

.card-Content.cartPages .card-body.bottomTwo .list-items {
    min-height: auto;
}

.cardCodadd {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    border-bottom: 1px solid #b6b6af;
}

.radioAddressBlock {
    display: flex;
}

.dilevryHereBtn {
    display: block;
    margin-left: 60px;
    margin-top: 0;
}

.row.deleiveryAddress .col-md-12 {
    padding: 0;
}

.cardCodadd.activeAddress {
    background: #ededeb;
}

.cardCodadd:last-child {
    border: none;
}

.row.BusinessBlockAdd .col-md-12 {
    padding: 0;
}

.cardCodadd.activeAddress .dilevryHereBtn .btn {
    width: 130px;
    margin-right: 10px;
}

.card-body.bottomTwo .editUserProfile {
    text-align: right;
    margin: 0 0 15px;
}

.row.PlaceOrderBottom .cardCodadd {
    background: #fff;
    border: 1px solid #cbcbcb;
    padding: 15px;
}

.row.PlaceOrderBottom .row.TotlaPyes {
    padding: 0;
}
/*card-details datalist end */
/*Product New Details Page*/
.mainProductLogo {
    background: #fff;
    text-align: center;
    padding: 5px;
    height: 180px;
}

    .mainProductLogo img {
        width: auto;
        height: 100%;
        margin: auto;
        object-fit: contain;
    }

.activeProductname {
    color: #4372c5;
    font-weight: 600;
    font-size: 26px;
    margin-top: 0;
    border-bottom: 1px solid #b6b6af;
    padding-bottom: 10px;
    margin-bottom: 0;
}

.ProdcutTopBrandName {
    margin: 15px 0;
}

.skuDetails {
    font-weight: normal;
}

    .skuDetails span {
        margin-right: 15px;
    }

        .skuDetails span:last-child {
            margin: 0;
        }

.canbeerSize {
    margin: 35px 0 0;
}

    .canbeerSize span {
        border-right: 2px solid #000;
        padding: 0 10px;
    }

        .canbeerSize span:first-child {
            padding-left: 0;
        }

        .canbeerSize span:last-child {
            border: none;
        }

.row.mbs-50 {
    margin-bottom: 50px;
}

.aboutHeading {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
    color: #4372c5;
    margin-bottom: 15px;
}

    .aboutHeading span {
        color: #696a6d;
    }

.activeProductPrice {
    font-size: 24px;
    color: #4372c5;
    font-weight: 600;
    margin-bottom: 8px;
}

.productQtyCheck h4 {
    font-weight: normal;
    font-size: 16px;
    margin: 0;
}

.qtyIncreses {
    margin: 25px 0 55px;
}

.qtyText {
    color: #4372c5;
    font-weight: 600;
    font-size: 18px;
    padding: 0 15px;
}

.qtyIncreses i {
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    width: 10px;
}

.cutosyText {
    padding-top: 25px;
    font-weight: normal;
}

.sizeBifercation {
    padding-top: 35px;
    font-weight: normal;
}

    .sizeBifercation span {
        font-weight: 600;
        min-width: 100px;
        display: inline-block;
    }

.collatrelDocs {
    background: #fff;
    position: relative;
    padding: 40px 30px;
}

    .collatrelDocs img {
        max-width: 250px;
        width: auto;
        height: 300px;
    }

    .collatrelDocs .downloadbtns {
        position: absolute;
        right: 28px;
    }

        .collatrelDocs .downloadbtns img {
            width: 50px;
            height: auto;
        }

.row.colds {
    margin-bottom: 50px;
}

.aboutsperator {
    font-weight: normal;
    margin: 35px 0 15px;
}

.galleryPic {
    margin: 0 0 30px;
    border: 1px solid #b6b6af;
    background: #fff;
}

    .galleryPic img {
        height: 300px;
        object-fit: cover;
        width: 100%;
    }

.productQtyImg .carousel {
    margin-top: 0px;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 340px;
    object-fit: contain;
    background-color: #fff;
}

.productQtyImg .item .thumb {
    width: 25%;
    cursor: pointer;
    float: left;
    margin: 0;
    padding: 0;
    height: 80px;
}

    .productQtyImg .item .thumb img {
        width: 100%;
        margin: 2px;
        height: 100%;
        object-fit: contain;
        background: #fff;
    }

.productQtyImg .item img {
    width: 100%;
}

.productQtyImg .carousel-control .glyphicon-chevron-left,
.productQtyImg .carousel-control .glyphicon-chevron-right {
    font-size: 20px;
}

.carousel-inner.increaseHeight .item img {
    height: 340px;
    object-fit: contain;
    background: #fff;
}

.card-Content.product-detailNewbg {
    background-color: #ededeb;
    background-image: none;
}

.productQtyImg .carousel .thumb {
    height: 80px;
    background: #fff;
}

    .productQtyImg .carousel .thumb.selected, .productQtyImg .carousel .thumb:hover {
        border: 1px solid #333;
    }

    .productQtyImg .carousel .thumb img {
        object-fit: contain;
        height: 100%;
    }

.addtocartBtns a {
    min-width: 100px;
    font-weight: 700;
    border-radius: 8px;
    padding: 8px 12px;
    text-transform: uppercase;
    border-color: #4d79c7;
    background-color: #4d79c7;
}

.addtocartBtns {
    margin: 35px 0 0;
}

.uploadIconspng.error {
    border-color: red;
}

.product-list-component .MuiTypography-h4 {
    width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
/*End Product New Details Page*/
/*Complaince Form*/
.fedralHeading {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 22px;
}

.uploadIconspng {
    border: 1px solid #b6b6af;
    padding: 15px;
    min-height: 242px;
    text-align: center;
    cursor: pointer;
}

    .uploadIconspng img {
        width: 168px;
        margin: auto;
        opacity: 0.5;
    }

.addDocText {
    margin-top: 18px;
    display: block;
    color: #757574;
}





.wizardShowOnly .stepwizard {
    display: table;
    width: 100%;
    position: relative;
    margin: 15px 0 20px;
}

.wizardShowOnly .stepwizard-row {
    display: table-row;
}

    .wizardShowOnly .stepwizard-row::before {
        top: 14px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 100%;
        height: 1px;
        background-color: #ccc;
        z-index: 0;
        left: 0;
    }

.wizardShowOnly .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}

.wizardShowOnly .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}


.wizardShowOnly .stepwizard-step p {
    margin-top: 0px;
    color: #666;
}

.wizardShowOnly .stepwizard .btn.disabled, .wizardShowOnly .stepwizard .btn[disabled],
.wizardShowOnly .stepwizard fieldset[disabled] .btn {
    opacity: 1 !important;
    color: #bbb;
}

.wizardShowOnly {
    margin: 0 0 20px;
}

    .wizardShowOnly .btn.btn-default.btn-circle.Pending {
        background-color: orange;
        border-color: orange;
        color: #fff;
    }

    .wizardShowOnly .btn.btn-default.btn-circle.ready {
        background-color: #6c6a6a;
        border-color: #6c6a6a;
        color: #fff;
    }

.wizardShowOnly {
    margin: 0 0 20px;
    background: #fff;
    padding: 25px 15px 15px;
}

    .wizardShowOnly:last-child {
        margin: 0;
    }

.swal2-actions button {
    min-width: 98px;
    font-size: 14px !important;
}

.swal2-deny {
    background-color: #6c6c6c !important;
    border-color: #6c6c6c !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline:focus {
    box-shadow: none !important;
}

.swal2-actions button:focus-visible {
    outline: none !important;
}

.swal2-html-container {
    font-size: 16px !important;
    font-family: 'Libre Franklin';
}

.modal-header {
    background: #b6b6af;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.fileNamede span {
    width: 575px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.row.uplaodViews {
    margin-top: 25px;
}

.Content-body.fedralListTable .btn {
    min-width: 114px;
}
/*End Complaince Form*/
/*Order History Filter*/
.customFilterOptions {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.FilterOptionsBtn {
    background: #b6b6af;
    height: 35px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0 15px;
    cursor: pointer;
}

    .FilterOptionsBtn.active {
        background: #496cac;
        color: #fff;
    }
/*Order History Filter End*/
.InsertForms .form-group .react-datepicker-wrapper {
    display: block;
    width: 100%;
}

    .InsertForms .form-group .react-datepicker-wrapper input {
        width: 100%;
        height: 34px;
        border: 1px solid #b6b6af;
        border-radius: 0;
        color: #555;
        font-size: 14px;
        padding: 6px 12px;
    }

        .InsertForms .form-group .react-datepicker-wrapper input.haverror {
            border-color: red;
        }

        .InsertForms .form-group .react-datepicker-wrapper input:focus-visible {
            outline: none;
        }

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -30px !important;
}

.Content-body .form-group .selectTodesign.error {
    border-color: transparent !important;
}

.custom-dollor th {
    vertical-align: middle !important;
}

.react-datepicker__month-container {
    float: left;
    width: 100%;
}

.react-datepicker-popper,
.react-datepicker-popper > div.react-datepicker-wrapper,
.react-datepicker-popper > div > div.react-datepicker__input-container
.react-datepicker-popper > div > div.react-datepicker__input-container input {
    width: 100%;
}

.react-datepicker {
    font-size: 14px !important;
    width: 100%;
    max-width: 250px;
}

.react-datepicker__day {
    font-size: 13px !important;
}

.react-datepicker__day-names, .react-datepicker__week {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.react-datepicker__day-name {
    font-weight: 600 !important;
}

.react-datepicker__current-month {
    font-size: 13px !important;
    top: -4px;
    position: relative;
}

.react-datepicker__day-names {
    margin-bottom: -5px !important;
}
/*Super Admin Login*/
.lgoinWrapper {
    background: #d7d7d3;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.whitebgContain {
    background: #fff;
    padding: 30px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.loignPop {
    max-width: 480px;
    margin: auto;
}

    .loignPop h3 {
        text-align: center;
        margin: 0 0 25px;
    }

.whitebgContain .logibtns {
    text-align: center;
    margin-top: 35px;
}

.whitebgContain .form-group {
    margin-bottom: 20px;
}

.copyrightLogin {
    text-align: center;
    margin: 15px 0;
    font-size: 13px;
}

.whitebgContain .form-group .form-control {
    border-radius: 0;
}

.loginLogoImg {
    text-align: center;
    margin: 0 0 25px;
}

    .loginLogoImg img {
        width: 120px;
    }
/*End Super Admin Login*/
/*Super Admin Header*/
.HeaderTag.SuperAdmin {
    background-color: #4372c5 !important;
}

.mainAdminWrapper.superAdminbg {
    background: #f6f8fa;
}
/*End Super Admin Header*/
/*Dashboard Super Admin*/
.blankHeight {
    height: 30px;
}

.superHeadingTOpHEad h2 {
    color: #303030;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 28px;
}

.analytics-sparkle-line {
    padding: 20px;
    background: #fff;
}

.analytics-content h5 {
    font-size: 14px;
    color: #303030;
    margin: 0 0 10px;
}

.analytics-content h2 {
    font-size: 20px;
    color: #303030;
    font-weight: 600;
}

.analytics-content .progress {
    height: 5px;
    margin-bottom: 0;
}

.analytics-sparkle-line .analytics-content .tuition-fees {
    font-size: 14px;
    float: right;
    margin-top: 8px;
    color: #999;
    font-weight: 300;
}

.product-sales-chart {
    padding: 20px;
    background: #fff;
    min-height: 504px;
}

.box-title-top {
    font-size: 16px;
    color: #303030;
    font-weight: 600;
    margin: 0 0 10px;
}

.analysis-progrebar .analysis-progrebar-content h5 {
    font-size: 16px;
    color: #303030;
    font-weight: 600;
    margin-top: 12px;
}

.storage-right {
    float: right;
    margin-bottom: 0px;
    margin-top: -25px;
    font-size: 16px !important;
}

.analysis-progrebar .analysis-progrebar-content h2 {
    font-size: 16px;
    color: #303030;
    font-weight: 600;
}

.analysis-progrebar-content .small p {
    font-size: 14px;
    color: #303030;
    margin: 0px;
}

.analysis-progrebar.res-mg-t-30 {
    padding: 20px;
    background: #fff;
    margin-bottom: 25px;
}

.analysis-progrebar-content .progress {
    width: 100%;
    height: 5px;
    margin-bottom: 6px;
}

.product-sales-area.mg-tb-30 {
    margin-top: 30px;
}

.ug-2 .progress-bar {
    background: #933EC5;
}

.ug-3 .progress-bar {
    background: #65b12d;
}

.ug-4 .progress-bar {
    background: #D80027;
}

.white-box {
    background: #fff;
    padding: 20px;
}

.basic-list {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

    .basic-list li {
        display: block;
        padding: 11.6px 0;
        border-bottom: 1px solid rgba(120,130,140,.13);
        line-height: 27px;
        color: #303030;
        font-size: 14px;
    }

    .basic-list .label {
        margin-top: 0px;
        padding: 7px 10px;
        font-size: 12px;
        border-radius: 2px;
    }

.label-purple.label-2 {
    background: #933EC5;
}

.label-purple {
    background-color: #9675ce;
}

    .label-purple.label-7 {
        background: #555;
    }

.progress-bar.progress-bar-danger {
    background: #933EC5;
}

.progress-bar.progress-bar-info {
    background: #65b12d;
}

.progress-bar.progress-bar-inverse {
    background: #D80027;
}

.country-state {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

    .country-state li {
        margin: 25px 0px;
    }

    .country-state h2 {
        font-size: 18px;
        color: #444;
        margin-bottom: 2px;
        font-weight: 600;
    }

    .country-state small {
        font-size: 14px;
        color: #303030;
    }

.text-danger.ctn-ic-1 {
    color: #006DF0;
}

.country-state .progress {
    height: 4px;
    margin-bottom: 0px;
}

.progress-bar.progress-bar-danger.ctn-vs-1 {
    background: #006DF0;
}

.country-state li:last-child {
    margin-bottom: 0px;
}

.progress-bar.progress-bar-info.ctn-vs-2 {
    background: #933EC5;
}

.progress-bar.progress-bar-success.ctn-vs-3 {
    background: #65b12d;
}

.progress-bar.progress-bar-success.ctn-vs-4 {
    background: #D80027;
}

.progress-bar.progress-bar-inverse.ctn-vs-5 {
    background: #555;
}

.mainAdminWrapper.superAdminbg .rightContetBlock {
    padding-bottom: 20px;
}

.HeaderContent.SuperAdminToolbar .MuiButtonBase-root svg {
    color: #fff;
}

.HeaderTag.SuperAdmin .profileHeadSide button svg {
    color: #b2b2b2;
}

.analysis-progrebar.res-mg-t-30:last-child {
    margin-bottom: 0;
}

.courses-area.mg-b-15 {
    margin-top: 30px;
}

/*End Dashboard Super Admin*/
/*Invoice Page*/
.LogoInvoice img {
    width: 120px;
}

.InvoiceTextHead {
    text-align: right;
}

    .InvoiceTextHead h3 {
        font-weight: 700;
        margin: 25px 0 0;
    }

.row.orderHeader p {
    margin: 0 0 5px;
}

.row.orderHeader {
    margin-top: 45px;
}

.orderIdText {
    text-align: right;
}

.orderNumbersId {
    font-weight: 800;
}

.billingImforArea h3 {
    font-size: 16px;
    font-weight: 800;
    margin: 0 0 15px;
}

.row.informationBiligns {
    margin-top: 35px;
}

.callAdress p {
    margin: 0 0 5px;
}

.callAdress.samllwidth p {
    width: 335px;
}

.transId {
    font-weight: 700;
}

.amountDallrs {
    text-align: right;
}

.mainPrName {
    display: block;
    font-weight: 700;
}

.brandNameSm {
    font-size: 14px;
}

.row.puchaseHistoryTable {
    margin-top: 25px;
}

.qtyTextr {
    text-align: center;
}

    .qtyTextr.grandtotals {
        font-weight: 800;
    }

.amountDallrs.finalPrice {
    font-weight: 800;
}

.row.puchaseHistoryTable .table > tbody > tr > td {
    vertical-align: middle;
}

.row.Prinatblebtn .formBtns .btn {
    margin-right: 8px;
}

.qtyTextr.inputwidth input {
    max-width: 85px;
    margin: auto;
    width: 100%;
}

.formBtns.nutiplebtnsarea {
    position: fixed;
    bottom: 0;
    padding: 10px 0;
}

.formBtns.submit {
    padding-left: 1%;
}

.invoicePagesWrapper.ssdinvoice {
    height: calc(100vh - 65px);
    overflow-y: scroll;
}
/*Invoice Page End*/
.expandable-tablerow {
    display: block;
    float: left;
    width: 100%;
    padding: 5px 20px;
    padding-left: 64px;
}

.expandable.col.one {
    max-width: 250px;
    width: 100%;
}

.expandable.col.two {
    max-width: 229px;
    width: 100%;
}

.expandable.col.twelve {
	max-width: 1400px;
	width: 100%;
}

.expandable {
    text-align: left;
    float: left;
    width: 20%;
}

.inventoryTable.mange-Fonts .expandable.col.six {
    width: 120px;
}

.inventoryTable.mange-Fonts .expandable.col.seven {
    width: auto;
}

.inventoryTable .rdt_TableCell {
    font-size: 16px;
}

.inventoryTable.mange-Fonts .rdt_TableCell, .inventoryTable.mange-Fonts .expandable-tablerow {
    font-size: 13px;
}

.invSelectInventory {
    max-width: 300px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.inventoryTable {
    position: relative;
}

.expandable.col.three {
    text-align: left;
    width: 229px;
}

.expandable.col.five {
    text-align: right;
    width: 200px;
}

.expandable.col.four {
    text-align: right;
    width: 200px;
}

table.table.child-table {
    background: #ededeb;
}

.compliance-tablerow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 20px 6px 35px;
}

.inventorytbds .expandable.col.one.bol {
    max-width: 100%;
}

    .inventorytbds .expandable.col.one.bol .bold .size {
        font-size: large;
    }

.inventorytbds .expandable.col.one {
    max-width: 200px;
    width: 100%;
}

.inventorytbds .expandable.col.two {
    max-width: 200px;
    width: 100%;
}

.inventorytbds .expandable.col.four {
    text-align: left;
    width: 150px;
}

    .inventorytbds .expandable.col.four.pdLeft {
        padding-left: 26px;
    }

.inventorytbds .expandable.col.three {
    text-align: left;
    width: 200px;
}

.inventorytbds .expandable.col.five {
    text-align: left;
    width: 120px;
}

span.remarkCols {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
/*Buyer Dashboard*/
.dashboardMainbuyer {
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 40px;
    font-weight: 800;
    color: #414048;
    margin: 30px 0;
}

    .dashboardMainbuyer span {
        color: #8e8e8a;
    }

.shopBuyerOrange {
    background: #ff9027;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    max-width: 520px;
    margin: 20px auto 60px;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 22px;
    font-weight: 700;
}

    .shopBuyerOrange a {
        padding: 10px 0;
        color: #fff;
        display: block;
        text-decoration: none;
    }

        .shopBuyerOrange a:focus-visible {
            outline: none;
        }

.orderDetialsListing {
    border: 1px solid #414048;
    background: #fff;
    padding: 15px;
    position: relative;
}

    .orderDetialsListing ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .orderDetialsListing ul li {
            margin: 0 0 15px;
        }

            .orderDetialsListing ul li a {
                color: #000;
            }

            .orderDetialsListing ul li:last-child {
                margin: 0;
            }

            .orderDetialsListing ul li span {
                margin-right: 8px;
                display: inline-block;
            }

                .orderDetialsListing ul li span:last-child {
                    margin: 0;
                }

.orderHisBtn {
    background: #414048;
    color: #fff;
    max-width: 205px;
    text-align: center;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 8px;
    line-height: 19px;
    position: relative;
    margin: 45px auto auto;
}

    .orderHisBtn a {
        color: #fff;
        text-decoration: none;
    }

.yourOrderheadings {
    font-size: 22px;
    font-weight: 600;
    color: #414048;
    margin: 0 0 15px;
}

.row.orderAndPopular .orderDetialsListing {
    min-height: 284px;
}

.putSliderHere img {
    width: 100%;
    height: 282px;
    object-fit: contain;
}

.SliderItemDetials {
    position: absolute;
    bottom: 20px;
    background: #4d79c7;
    color: #fff;
    width: 120px;
    text-align: center;
    border-radius: 8px;
    padding: 5px 8px;
    right: 20px;
    font-weight: 600;
    border: 1px solid #fff;
    cursor: pointer;
}

    .SliderItemDetials a {
        color: #fff;
        text-decoration: none;
    }

.row.fourProductsBoxs {
    margin-top: 55px;
}

.productFoourCard {
    background: #fff;
    border: 1px solid #414048;
    padding: 15px;
}

    .productFoourCard ul {
        padding: 0;
        margin: 0;
        min-height: 266px;
    }

        .productFoourCard ul li {
            list-style: none;
            margin: 0 0 12px;
        }

            .productFoourCard ul li:last-child {
                margin: 0;
            }

.fourProductsBoxs .yourOrderheadings {
    text-align: center;
}

.allfavList {
    background: #414048;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 8px;
    max-width: 200px;
    margin: 25px auto;
    cursor: pointer;
}

    .allfavList a {
        color: #fff;
        text-decoration: none;
    }

.productFoourCard.slideinsdebox img {
    width: auto;
    height: 359px;
    margin: auto;
    text-align: center;
}

.productFoourCard.slideinsdebox {
    text-align: center;
    min-height: 390px;
}

.popularBrewsSlider {
    background: #fff;
    min-height: 285px;
}

.row.ftBottom {
    margin-top: 55px;
    margin-bottom: 55px;
}

    .row.ftBottom .shopBuyerOrange {
        margin: auto;
    }

.row.orderAndPopular .orderDetialsListing .col-md-6.orderbns ul {
    min-height: 200px;
}

.row.orderAndPopular .orderDetialsListing .col-md-6.orderbns .orderHisBtn {
    position: absolute;
    bottom: -49px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.row.orderAndPopular .popularBrewsSlider {
    background: #fff;
    height: 280px;
}

    .row.orderAndPopular .popularBrewsSlider .MuiPaper-root.MuiPaper-elevation {
        box-shadow: none !important;
    }

.orderAndPopular .orderDetialsListing ul li a {
    color: #000;
}

.orderAndPopular .orderDetialsListing ul li span {
    margin-right: 0;
    display: inline-block;
    width: 33.3%;
}

    .orderAndPopular .orderDetialsListing ul li span.orderDallrPrice {
        text-align: right;
    }
/*End Buyer Dashboard*/
/*Product List Buyer Custom Filter*/
.rangesliderDesign {
    display: flex;
    height: 34px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

    .rangesliderDesign span {
        position: absolute;
    }

    .rangesliderDesign .input-range {
        max-width: 60%;
        margin: auto;
        left: -28px;
        position: relative;
    }

    .rangesliderDesign span.suffixVal {
        right: 0;
    }

    .rangesliderDesign span .input-range__label-container {
        display: none !important;
    }
/*End Product List Buyer Custom Filter*/
.productFoourCard.slideinsdebox img.image-slide {
    height: 327px;
    width: auto;
    object-fit: contain;
    width: 100%;
}

.removeBga {
    background: none !important;
    box-shadow: none !important;
}
/*Brewer Dashboard Design*/
.mainAdminWrapper.brewersDashArea {
    background: #ff9027;
}

    .mainAdminWrapper.brewersDashArea .headingTop {
        background: #5a392f;
    }

    .mainAdminWrapper.brewersDashArea .card-Content {
        background: #e2dcd1 url("../images/card-bg.png") no-repeat;
    }

    .mainAdminWrapper.brewersDashArea .dashboardMainbuyer {
        color: #5a392f;
    }

        .mainAdminWrapper.brewersDashArea .dashboardMainbuyer span {
            color: #ff9027;
        }

    .mainAdminWrapper.brewersDashArea .headingTop h1 {
        color: #cd946d;
    }

    .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li {
        margin: 0 0 15px;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
    }

        .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li:last-child {
            margin: 0;
        }

        .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li a {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #000;
            text-decoration: none;
        }

    .mainAdminWrapper.brewersDashArea .orderDetialsListing {
        padding: 15px 15px 20px;
        height: 392px;
    }

        .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li span {
            margin-right: 0px;
            display: inline-block;
        }

    .mainAdminWrapper.brewersDashArea .orderHisBtn {
        background: #5a392f;
        margin: auto;
        padding: 0;
    }

        .mainAdminWrapper.brewersDashArea .orderHisBtn a {
            padding: 10px 30px;
            display: block;
            margin: 20px 0 0;
        }

.productFoourCard.InventoryCard {
    min-height: 392px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

    .productFoourCard.InventoryCard a {
        padding: 10px 30px;
        display: block;
        background: #5a392f;
        color: #fff;
        text-align: center;
        font-weight: 600;
        line-height: 19px;
        max-width: 225px;
        text-decoration: none;
        border-radius: 8px;
        width: 100%;
    }

.fixed-bottombtns {
    margin: 15px 0 0;
}

    .fixed-bottombtns .btns {
        padding: 10px 10px;
        display: block;
        background: #5a392f;
        color: #fff;
        text-align: center;
        font-weight: 600;
        line-height: 19px;
        max-width: 100%;
        text-decoration: none;
        border-radius: 8px;
        width: 100%;
        box-shadow: none;
        border: 1px solid #5a392f;
        text-transform: uppercase;
    }

.productFoourCard.InventoryCard a:focus-visible {
    outline: none;
}

.mainAdminWrapper.brewersDashArea .productFoourCard {
    min-height: 392px;
}

.row.threeMainBoxe .yourOrderheadings {
    text-align: center;
    color: #5a392f;
}

.cardParas {
    margin: 15px 0 0;
    text-align: left;
}

.row.threeMainBoxe.btmpostions {
    margin-top: 55px;
    margin-bottom: 25px;
}

.mainAdminWrapper.brewersDashArea .row.threeMainBoxe.btmpostions .productFoourCard {
    height: 392px;
}

.priflePicdata {
    height: 100%;
}

    .priflePicdata img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

.productFoourCard.profileimgBlocks {
    padding: 0;
    border: none;
}

.fiexedRightCols .yourOrderheadings {
    text-align: center;
    color: #5a392f;
}

.addtotaskList a {
    color: #4d79c7;
    text-decoration: none;
}

.addtotaskList {
    margin: 0 0 8px;
}

.productFoourCard.Fixedright ul li {
    padding-left: 25px;
    background: url("../images/circle-icons.png") no-repeat;
    background-size: 20px;
    cursor: pointer;
}

    .productFoourCard.Fixedright ul li.active {
        background: url("../images/filled-circle-icons.png") no-repeat;
        background-size: 20px;
    }

.mainAdminWrapper.brewersDashArea .fiexedRightCols .productFoourCard {
    height: 929px;
    border-color: transparent;
}

.col-md-3.fiexedRightCols {
    padding-right: 0;
}

.productFoourCard.Fixedright ul li.active {
    text-decoration: line-through;
}

.row.threeMainBoxe.btmpostions .orderDetialsListing ul {
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 276px;
}

.mainAdminWrapper.brewersDashArea .orderDetialsListing ul {
    min-height: 276px;
}
/*End Brewer Dashboard Design*/
.checkbox.rightTextShift label {
    padding-left: 0;
}

.checkbox.rightTextShift input {
    margin-left: 10px;
}

.checkbox.rightTextShift label span {
    width: 150px;
    display: inline-block;
}
/*Data Table Search*/
.dataTableSearchBox .data-table-extensions-filter {
    background: #fff;
    float: right !important;
    padding-left: 5px;
    border-radius: 0;
}

.dataTableSearchBox .data-table-extensions {
    padding: 0;
    margin: 0 0 10px;
}

.dataTableSearchBox .data-table-extensions-filter label.icon {
    margin: 0 !important;
    height: 30px !important;
}

.dataTableSearchBox .data-table-extensions > .data-table-extensions-filter > .filter-text {
    border: none !important;
}
/*End Data Table Search*/
.toolTipData {
    font-size: 14px;
    font-weight: normal;
}

a.customPLusIcon i {
    font-size: 24px;
}

.norecordFound {
    min-height: 190px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editUserProfile.mb-15 {
    margin: 0 0 15px;
}

.cartItem.row.selectBuyers {
    border: none;
    padding-bottom: 0;
}

.twoAnchorBtns a {
    margin-right: 5px;
}

.tbaShowcase .rdt_TableCell {
    padding-top: 3px;
    padding-bottom: 3px;
}

.list-group.list-height-data {
    max-height: 390px;
    overflow-y: scroll;
}

li.list-group-item {
    border-radius: 0px !important;
}

.list-height-data::-webkit-scrollbar, .ps-sidebar-container::-webkit-scrollbar, .agreement-box::-webkit-scrollbar {
    width: 10px !important;
}

.list-height-data::-webkit-scrollbar-track, .ps-sidebar-container::-webkit-scrollbar-track, .agreement-box::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
}

.list-height-data::-webkit-scrollbar-thumb, .ps-sidebar-container::-webkit-scrollbar-thumb, .agreement-box::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 7px rgba(0,0,0,0.3) !important;
}

.cartItem.row.align-items-start.carteients .col-lg-2.col-sm-2.col-md-2.col-2.mb-2 {
    max-width: 140px;
    width: 100%;
}

.col-lg-2.col-sm-2.col-md-2.col-2.ProducQtyd {
    width: 18%;
}

.col-lg-2.col-sm-2.col-md-2.col-2.text-right.LadtPriost {
    width: 20%;
}

.billingImforArea.qr-code {
    position: absolute;
    right: 10px;
}

    .billingImforArea.qr-code canvas {
        float: right;
    }

span.bolidview {
    display: block;
    clear: both;
    position: relative;
    top: 5px;
}

.greenBtndbottom {
    text-align: center;
    margin: 25px 0;
}

    .greenBtndbottom a {
        background: #70ad47;
        color: #fff;
        padding: 8px 12px;
        border-radius: 8px;
        font-weight: 700;
        text-decoration: none;
    }
/*Welcome Bevport Page*/
.rightContetBlock.welcomebevText .card-heading.topsubheading {
    margin-top: 0;
    text-align: center;
    margin-bottom: 25px;
    display: block;
}

.welcomeSubText {
    padding: 0 35px;
    text-align: center;
}

.alphalistnc li {
    list-style: lower-alpha;
}

.rightContetBlock.welcomebevText .card-heading.topsubheading.text-left {
    text-align: left;
}

.greenBtndbottom.text-left {
    text-align: left;
}

.greenBtndbottom button {
    background: #70ad47;
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 700;
    text-decoration: none;
    border: none;
}

.formBtns.nutiplebtnsarea .btn {
    border-radius: 8px;
    padding: 8px 12px;
    position: relative;
}

.form-group.havecheckbox input.chcekcontrol.form-control {
    position: absolute;
    right: 0;
    top: 30px;
    width: 20px;
    box-shadow: none;
}

.form-group.havecheckbox {
    position: relative;
    max-width: 220px;
}

.form-group.Product-Label-Front {
    max-width: 220px;
    position: relative;
}

.form-group.havecheckbox input.form-control {
    width: 100%;
    max-width: 150px;
}

.formBtns.nutiplebtnsarea button {
    font-weight: 600;
}

label.visibilty-hidden {
    width: 100%;
    visibility: hidden;
}

.fixeatottom {
    margin: 10px 0 0;
}

p.makebold {
    font-weight: 700;
}

ul.alpahabetic-lowe {
    list-style-type: lower-alpha;
}

.agreement-box {
    height: 380px;
    overflow-y: scroll;
    background: #fff;
    padding: 10px 25px;
    margin-bottom: 30px;
}

.nfobox {
    max-width: 300px;
    border: 1px solid #000;
    padding: 10px;
    margin: 10px 0px;
}

    .nfobox p {
        margin: 0;
    }

p.romannumber {
    padding-left: 25px;
}

.table-responsive.addbgs {
    background: #b6b6af;
    margin: 5px 0 15px;
}

.newLabel {
    background: #ff9027;
    color: white;
    padding: 3px 20px;
    text-transform: uppercase;
    position: absolute;
    top: 13px;
    left: -24px;
    z-index: 2;
    transform: rotate(-45deg);
    font-size: 11px;
    padding-left: 14px;
    width: 106px;
    text-align: center;
}

.approvedLabel {
    background: green;
    color: white;
    padding: 3px 20px;
    text-transform: uppercase;
    position: absolute;
    top: 13px;
    left: -24px;
    z-index: 2;
    transform: rotate(-45deg);
    font-size: 11px;
    padding-left: 14px;
    width: 106px;
    text-align: center;
}


.rejectedLabel {
    background: #fd0000;
    color: white;
    padding: 3px 20px;
    text-transform: uppercase;
    position: absolute;
    top: 13px;
    left: -24px;
    z-index: 2;
    transform: rotate(-45deg);
    font-size: 11px;
    padding-left: 14px;
    width: 106px;
    text-align: center;
}

.showdata-deivisoin {
    display: flex;
    justify-content: space-between;
    margin: 0;
    min-height: 34px;
    align-items: center;
}

.label-text input {
    width: 120px;
    text-align: right;
}

td.showdata-inputype .label-text input {
    width: 120px;
    text-align: right;
    margin: auto;
}

.showdata-deivisoin .label-value {
    font-weight: 600;
}

.grandTotalDivs {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-weight: 700;
    font-size: 18px;
}

.contaedItemsList {
    padding: 5px 15px;
    background: #e6e6e6;
}

    .contaedItemsList:nth-child(2n) {
        background: #d0d0d0;
    }

.wholeCompoundRight.hideoverfol {
    overflow-y: hidden;
}

.addProfilePopup.makewidthgiger .modal-body {
    width: 100%;
    min-width: 600px;
}

.addProfilePopup.makewidthgiger .modal-footer {
    text-align: center;
}

.addProfilePopup.makewidthgiger .modal-body textarea {
    width: 100%;
    max-width: 600px;
    min-height: 90px;
}

span.showcasetext {
    display: none;
}

span.havetootip:hover span.showcasetext {
    display: block;
    position: absolute;
    top: -30px;
    background: #000;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 13px;
    padding: 4px;
    border-radius: 5px;
    margin: auto;
    left: 0;
    width: 120px;
    right: 0;
    height: auto;
    white-space: normal;
}

span.havetootip .fa {
    position: relative;
    left: 3px;
}

.row.Prinatblebtn .formBtns.nutiplebtnsarea .btn[disabled] {
    background: #656565;
    border-color: #656565;
    color: #ccc;
}

.uaserades {
    font-size: 16px;
    font-weight: 500;
    color: #414048;
    margin-bottom: 0px;
}

.form-group.insturcsiotn textarea.form-control {
    width: 100% !important;
}

.add-removebtn.text-right {
    text-align: left !important;
}

.table-responsive.addbgs table {
    margin-bottom: 0;
}

span.label-text.stinftest {
    font-weight: 700;
}

span.smaltxrs {
    font-size: 14px;
    font-weight: normal;
}

.instructions {
    background: #e2dcd1;
    padding: 10PX;
    margin: 0 0 25px;
    border: 1px dashed #8e8c8ccc;
    font-size: 13px;
}

    .instructions h3 {
        margin: 0 0 5px;
        font-size: 20px;
        font-weight: 700;
    }

    .instructions p {
        margin: 0 0 5px;
    }

.containercheck {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


    .containercheck input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}


.containercheck:hover input ~ .checkmark {
    background-color: #ccc;
}


.containercheck input:checked ~ .checkmark {
    background-color: #2196F3;
}


.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.containercheck input:checked ~ .checkmark:after {
    display: block;
}

.containercheck .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.visiblitypro {
    visibility: hidden;
}

.form-group.havecheckbox label.containercheck {
    position: absolute;
    top: 33px;
    right: -20px;
    padding: 0;
}

.containercheck input.greenCheck:checked ~ .checkmark {
    background-color: #5cb85c;
}

.form-group.Product-Label-Front label.containercheck {
    position: absolute;
    right: 0px;
    width: 30px;
    padding: 0;
}

.form-group.Product-Label-Front .mainThumbnail {
    height: 150px;
    margin-bottom: 0;
    width: 120px;
}

    .form-group.Product-Label-Front .mainThumbnail img {
        width: 100% !important;
        height: 100% !important;
    }

span.underline {
    border-bottom: 1px solid #000;
    font-style: italic;
    font-weight: 600;
}
/*End Welcome Bevport Page*/
.sc-eLSjS {
    overflow: unset !important;
    white-space: unset !important;
    text-overflow: unset !important;
}

.mainThumbnail.abc span.deleteThis {
    z-index: 1;
}

.managescroll {
    height: calc(100vh - 290px);
    overflow: scroll !important;
}

.showblock {
    display: block;
}

.preview-img-icons span.deleteThis {
    top: -8px;
    right: -9px;
}

.cusotomPagination ul li button:hover {
    color: #fff;
    background: #4372c5e8;
}


.cusotomPagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 0;
}

    .cusotomPagination ul li {
        list-style-type: none;
        display: inline-block;
    }

    .cusotomPagination ul {
        padding: 0;
        margin: 0;
    }

.Content-body.dataTableSearchBox.fedralListTable .rdt_Pagination button#pagination-first-page, .rdt_Pagination #pagination-last-page {
    display: none;
}

@media (min-width:1200px) and (max-width:1600px) {
    .mainAdminWrapper.brewersDashArea .productFoourCard {
        min-height: 432px !important;
    }

    .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li {
        font-size: 12px;
    }

    .mainAdminWrapper.brewersDashArea .orderDetialsListing {
        min-height: 432px !important;
    }
}

@media only screen and (max-width:1200px) {
    .threeMainBoxe .col-md-4 {
        width: 50%;
    }

    .col-md-3.fiexedRightCols {
        padding-right: 0;
        padding-left: 0;
    }

    .productFoourCard ul li {
        font-size: 14px;
    }

    .productFoourCard.InventoryCard a {
        font-size: 13px;
    }

    .row.orderAndPopular .col-md-7 {
        width: 100%;
        margin-bottom: 25px;
    }

    .row.orderAndPopular .col-md-5 {
        width: 100%;
    }

    .row.fourProductsBoxs .col-md-3 {
        width: 50%;
        margin-bottom: 25px;
    }

    .productListingPage .col-md-3.mb-20 {
        width: 50%;
        margin: auto auto 25px;
    }

    .row.PlaceOrderBottom .row.TotlaPyes .col-lg-10.col-sm-10.col-md-10.col-10 {
        width: 100%;
        margin: 0 0 15px;
    }

    .row.PlaceOrderBottom .row.TotlaPyes .col-lg-2.col-sm-2.col-md-2.col-2.text-right {
        width: 25%;
    }
}

@media only screen and (max-width:992px) {
    .ps-collapsed.mycustomSidebar {
        display: none;
    }

    .wholeCompoundRight.fullwidth .RightContent {
        margin-left: 0;
    }

    .wholeCompoundRight.fullwidth {
        margin-left: 0;
    }

    .mainAdminWrapper {
        overflow-x: hidden;
    }

    .footerDivision {
        position: relative;
    }

    .threeMainBoxe .col-md-4 {
        width: 50%;
        float: left;
    }

    .col-md-3.fiexedRightCols {
        padding-right: 15px;
        padding-left: 15px;
    }

    .mainAdminWrapper.brewersDashArea .fiexedRightCols .productFoourCard {
        height: auto;
        border-color: transparent;
        min-height: auto;
    }

    .customPositionFilters .col-md-2,
    .customPositionFilters .col-md-3,
    .customPositionFilters .col-md-2,
    .customPositionFilters .col-md-4 {
        width: 50%;
        float: left;
        margin: 0 0 10px;
    }

    .customPositionFilters {
        margin-bottom: 30px;
    }

    .productListingPage .col-md-3.mb-20 {
        width: 50%;
        float: left;
    }

    .Content-body.productListingPage .col-md-3.mb-20 .product-list-component .ProductListCardContent {
        min-height: 260px;
    }

    .Content-body .col-md-3.mb-5 {
        width: 50%;
        float: left;
    }

    .twoAnchorBtns .btn {
        margin: 0 0 5px;
        width: 76px;
        display: block;
    }

    #clickimgproducer {
        margin: 15px 0 0;
    }

    .row.orderAndPopular .orderDetialsListing .col-md-6.orderbns .orderHisBtn {
        bottom: 0;
    }

    .row.fourProductsBoxs .col-md-3 {
        float: left;
    }

    .formBtns.nutiplebtnsarea {
        position: relative;
        bottom: 0;
        padding: 10px 0;
        width: 100%;
        left: 20px;
    }
}

@media only screen and (max-width:767px) {
    .rightContetBlock {
        height: 100%;
    }

    .mainAdminWrapper.brewersDashArea .orderDetialsListing {
        padding: 15px 10px 20px;
    }

        .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li a {
            font-size: 14px;
        }

    .row.threeMainBoxe.btmpostions .cardParas {
        min-height: 160px;
    }

    .productListingPage .col-md-3.mb-20 {
        width: 100%;
        float: none;
        max-width: 311px;
        margin: auto auto 30px;
    }

    .teamPageBtns .btn {
        font-size: 13px;
    }

    .Content-body.teamPageMeber .col-md-3.mb-5 {
        max-width: 312px;
        width: 100%;
        margin: auto;
        float: none;
    }

    .cartItem.row.align-items-start .col-lg-2.col-sm-2.col-md-2.col-2.text-right {
        text-align: left;
        margin: 5px 0;
    }

    .formBtns.nutiplebtnsarea .btn {
        margin: 0 0 8px;
        width: 170px;
    }

    .formBtns.nutiplebtnsarea {
        background: #fff;
    }

    .row.Prinatblebtn .formBtns.nutiplebtnsarea .btn {
        margin: auto auto 12px;
        display: block;
        width: 220px;
    }
}

@media only screen and (max-width:600px) {
    .productListingPage .col-md-3.mb-20 {
        width: 100%;
        float: inherit;
        max-width: 282px;
        margin: auto auto 30px;
    }

    .Content-body.productListingPage .col-md-3.mb-20 .product-list-component .ProductListCardContent {
        min-height: auto;
    }

    .threeMainBoxe .col-md-4 {
        max-width: 282px;
        float: none;
        width: 100%;
        margin: auto auto 35px;
    }

    .Content-body .nav-tabs > li > a {
        padding: 8px 6px;
    }

    .Content-body .col-md-3.mb-5 {
        width: 100%;
        float: none;
        max-width: 311px;
        margin: auto;
    }

    .row.fourProductsBoxs .col-md-3 {
        float: none;
        width: 100%;
        max-width: 282px;
        margin: auto auto 25px;
    }

    .formBtns.nutiplebtnsarea {
        text-align: center;
    }

    .row.Prinatblebtn .formBtns .btn {
        margin-right: 5px;
    }

    .formBtns.nutiplebtnsarea {
        left: 0;
    }

    .invoicePagesWrapper.ssdinvoice {
        height: 100%;
    }

        .invoicePagesWrapper.ssdinvoice .wizardShowOnly {
            padding-bottom: 0;
        }

        .invoicePagesWrapper.ssdinvoice .row.orderHeader {
            margin-top: 25px;
        }

        .invoicePagesWrapper.ssdinvoice .row.puchaseHistoryTable {
            margin-bottom: 20px;
        }

    .addProfilePopup.makewidthgiger .modal-body {
        width: 100%;
        min-width: 360px;
        max-width: 600px;
    }

    span.havetootip:hover span.showcasetext {
        display: block;
        position: absolute;
        top: -30px;
        background: #000;
        text-transform: capitalize;
        font-weight: normal;
        font-size: 13px;
        padding: 4px;
        border-radius: 5px;
        margin: initial;
        left: -50px;
        height: auto;
        white-space: normal;
        width: 320px;
        right: auto;
    }

    .callAdress.samllwidth p {
        width: 100%;
    }
}

@media only screen and (max-width:480px) {
    .dashboardMainbuyer {
        font-size: 30px;
    }

    .footerDivision p {
        font-size: 11px;
    }

    .mainAdminWrapper.brewersDashArea .orderDetialsListing ul li {
        font-size: 14px;
    }

    .customPositionFilters .col-md-2,
    .customPositionFilters .col-md-3,
    .customPositionFilters .col-md-2,
    .customPositionFilters .col-md-4 {
        width: 100%;
    }

    .Content-body .nav-tabs > li {
        width: 100%;
    }

        .Content-body .nav-tabs > li > a {
            border-radius: 4px;
        }

        .Content-body .nav-tabs > li.active > a,
        .Content-body .nav-tabs > li.active > a:focus {
            color: #000;
            cursor: default;
            background-color: #e9e5dd;
            border: 1px solid #b6b6af;
            border-bottom: 1px solid #b6b6af;
        }

        .Content-body .nav-tabs > li > a:hover {
        }

    .orderDetialsListing ul li {
        margin: 0 0 10px;
        font-size: 12px;
    }

    .formBtns.nutiplebtnsarea .btn {
        width: auto;
    }

    .addProfilePopup.makewidthgiger .modal-body {
        width: 100%;
        min-width: 300px;
        max-width: 600px;
    }

    span.havetootip:hover span.showcasetext {
        display: block;
        position: absolute;
        top: -30px;
        background: #000;
        text-transform: capitalize;
        font-weight: normal;
        font-size: 13px;
        padding: 4px;
        border-radius: 5px;
        margin: initial;
        left: -35px;
        height: auto;
        white-space: normal;
        width: 290px;
        right: auto;
        text-align: center;
    }
}


/*// prefiex and suffix style -- Example Used on scale (money bar) $0 to $500 on product List Page */
.prefiexval {
    color: white !important;
}

.suffixVal {
    color: white !important;
}


/*//  overide Mui autocomplete --> (dropdown) CSS to match our current style  */
.MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    background-color: white !important;
    height: 34px !important;
    font-size: 16px !important;
    align-content: center !important;
}

/*//  overide Mui autocomplete --> place Holder (Label)  */
.MuiOutlinedInput-input {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Libre Franklin' !important;
}

/*// custom image drop uploader on buyer (editProfile page) and (buyer registration page)---*/
.custom-input-drop-brewer {
    /* min-height: 100px; */
    /*
	border: 2px dashed #e9ebec;
	background: #ccc;*/
    /**/
    border: 2px dashed #a16a59;
    background: #e1b285;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 15px;
    cursor: pointer;
    max-height: 100px;
    font-size: 15px;
}

.dropzone.custom-input-drop-brewer.error {
    border: 2px dashed red;
    background: #d59898;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 15px;
    cursor: pointer;
    max-height: 100px;
    font-size: 15px;
}



.custom-input-drop-buyer {
    /* min-height: 100px; */

    border: 2px dashed #e9ebec;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 15px;
    cursor: pointer;
    max-height: 100px;
    font-size: 15px;
}

.dropzone.custom-input-drop-buyer.error {
    border: 2px dashed red;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 15px;
    cursor: pointer;
    max-height: 100px;
    font-size: 15px;
}



/* /// --------------------brewer upload compliance icon--------------------------*/
.uploadIconspng_brewer {
    border: 1px solid #b6b6af;
    /*padding: 15px;*/
    min-height: 100px;
    text-align: center;
    cursor: pointer;
    background-color: #e1b285;
}

    .uploadIconspng_brewer.error {
        border-color: red;
    }

    .uploadIconspng_brewer img {
        width: 50px;
        margin: auto;
        opacity: 0.5;
        height: 50px;
    }

.addDocText_brewer {
    /*margin-top: 18px;*/
    display: block;
    color: #3d3b3b;
    font-size: 15px;
}

/*-----------------------------------------------------------------------------------*/


/*------------------------------------ Brewer Container -----------------------------------------------*/
.brewerShowOnly {
    margin: 0 0 5px;
    /*background: #fff;*/
    padding: 25px 15px 15px;
}


    .brewerShowOnly .stepwizard {
        display: table;
        width: 100%;
        position: relative;
        margin: 15px 0 20px;
    }

    .brewerShowOnly .stepwizard-row {
        display: table-row;
    }

    .brewerShowOnly .stepwizard-step {
        display: table-cell;
        text-align: center;
        position: relative;
    }


    .brewerShowOnly .stepwizard-row::before {
        top: 14px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 100%;
        height: 1px;
        background-color: #ccc;
        z-index: 0;
        left: 0;
    }


    .brewerShowOnly .table-bordered {
        border: 1px solid #b6b6af;
    }

        .brewerShowOnly .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
            border: 1px solid #b6b6af;
        }




/*// class insert forms ---> above brewerShowOnly input UI*/
.InsertForms_Brewer {
    /* padding-top: 25px; */
}


.brewerShowOnly .btn.btn-default.btn-square.Pending {
    background-color: orange;
    border-color: orange;
    color: #fff;
}

.brewerShowOnly .btn.btn-default.btn-square.ready {
    background-color: #6c6a6a;
    border-color: #6c6a6a;
    color: #fff;
}

/*// ---------- Square steps completed pending, ready------------  */
.brewerShowOnly .btn-square {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    /*border-radius: 15px;*/
}
/*//-------------------------------------------------------------------------------------------------------------------*/

.activeStateLabel125 {
    display: inline;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
}

.activeStateCheckBox125 {
    accent-color: green;
}

/*// jesse dudley 4/19/2024 for margin on datatable extensions ---> so user can hover over export button and print buttons and see the words below */
.data-table-extensions {
	margin-bottom:20px !important;
}


/*// jesse Dudley Added 5/23/2024 form styling boostrap modal backdrop when opening a modal window ---> using react*/
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #7b7caf99 !important;
}




/*    // Bootstrap Modal Window ---> margin --> to make the modal window in the middle of the screen     */
@media (min-width: 768px) {
	.modal-dialog {
		width: 600px;
		margin: 250px auto !important;
	}
}

/*    // Bootstrap Modal Window invoiceDetails ---> margin --> to make the modal window in the middle of the screen     */
@media (min-width: 768px) {
	.invoiceDetailsModal .modal-dialog {
		width: 1200px;
		margin: 250px auto !important;
	}
}


/*// invoice details table hover    ---> use aside example form later  */ 
/*.invoiceTableRow .rdt_TableBody .rdt_TableRow:hover {
	cursor: pointer !important;
	background: #cad4f9;*/ /* Old browsers */
	/*cursor: pointer;
}*/



