html {
    scroll-behavior: smooth;
}

body {
    color: #414048;
    font-family: 'Libre Franklin';
    font-size: 16px;
    font-weight: 500;
}
/*Top Bar*/
.topbarnav {
    background: #e7e7e8;
}

.top-rightNav {
    float: right;
    font-size: 14px;
    color: #000;
}

    .top-rightNav a {
        color: #000;
    }

.makebtnright {
    float: right;
    width: auto;
}

.mainsubmenu .navbar-nav > li {
    float: none;
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 700;
}

.navbar-default .mainsubmenu .navbar-nav > li > a {
    color: #231f20;
}

.top-rightNav ul li {
    padding: 0 10px;
    line-height: 40px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
}

    .top-rightNav ul li a {
        text-decoration: none;
    }

.top-rightNav ul {
    margin: 0;
}
/*End Top Bar*/
.main-naivgation .navbar-brand img {
    height: 82px;
}

.main-naivgation .navbar {
    min-height: 100px;
    margin-bottom: 0;
}

.main-naivgation .navbar-default {
    background-color: #fff;
    border: none;
}

    .main-naivgation .navbar-default .navbar-toggle {
        border-color: transparent;
        margin-top: 18px;
    }

        .main-naivgation .navbar-default .navbar-toggle:focus, .main-naivgation .navbar-default .navbar-toggle:hover {
            background-color: transparent;
        }

.main-naivgation .navbar-brand {
    height: auto;
    padding: 8px 30px 5px;
    box-sizing: border-box;
    padding-left: 15px;
}

.main-naivgation ul.nav.navbar-nav {
    padding: 25px 0;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 930px;
    margin: auto;
}

.makebtnright a {
    background: #4d79c7;
    color: #fff;
    padding: 8px 20px;
    border-radius: 8px;
    text-transform: uppercase;
    display: block;
    text-decoration: none;
}

    .makebtnright a:hover {
        opacity: 0.9;
    }

.makebtnright {
    float: right;
    width: auto;
    height: 100px;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
}

.main-naivgation .navbar-default .navbar-nav > li > a.active {
    background-color: transparent;
    color: #1185d5;
}

.main-naivgation .navbar-default .navbar-nav > li > a:hover {
    color: #1185d5;
}

.col-md-5.simplecontent {
    background-color: #5a392f;
    height: 100%;
    padding: 0;
}

.col-md-7.manBgimg {
    padding: 0;
}

.manImg img {
    width: 100%;
    height: 608px;
}

.textSimply h1 .browntext {
    color: #946c5f;
    display: block;
}

.textSimply h1 {
    color: #cd946d;
}

.brownhite {
    color: #fff;
}

.simplyfyingDiv {
    position: relative;
}

.simplecontentimg img {
    height: 608px;
    width: 100%;
}

.moveTopUp {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.textSimply h1 {
    color: #cd946d;
    font-size: 48px;
    font-weight: 800;
    font-family: 'Libre Franklin', sans-serif;
    margin-top: 15px;
}

.textSimply {
    position: absolute;
    top: 200px;
    padding-right: 25px;
}

.row.homePageToops .textSimply {
    position: absolute;
    top: 70px;
    padding-right: 25px;
}

.brownhite.addWeight {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 18px;
}

.buyerHeasBtns .submitbtns .btn {
    background: #414049;
    border-color: #414049;
}

    .buyerHeasBtns .submitbtns .btn:hover {
        border-color: #52515a;
        background: #52515a;
    }

.buyerHeasBtns .submitbtns {
    margin-top: 25px;
}

.coming-fallbtn {
    margin: 0;
    font-size: 28px;
    color: #fff;
}

.buyerHeasBtns h1 {
    margin-top: 15px;
}
/*Join the Conversation*/
.newdistribution {
    background: #F7941D;
    padding: 20px 0 25px;
}

    .newdistribution h1 {
        font-size: 56px;
        color: #fff;
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 800;
        margin-bottom: 25px;
    }

.videoside p {
    color: #fff;
}

.buttonbrownTwo {
    margin-top: 35px;
}

    .buttonbrownTwo a {
        background: #5A392F;
        color: #fff;
        padding: 8px 24px;
        margin-right: 25px;
        display: inline-block;
        font-size: 16px;
        border-radius: 8px;
        text-decoration: none;
        font-weight: 700;
        font-family: 'Libre Franklin', sans-serif;
    }
/*End join the Conversation*/
/*Brewers And Buyers*/
.col-md-6.brewersPart {
    padding: 0;
    background: #cd946d;
}

.col-md-6.buyersPart {
    padding: 0;
    background: #b6b6af;
}

    .col-md-6.buyersPart .buyerbg img, .col-md-6.brewersPart .brewersBg img {
        max-height: 730px;
        width: 100%;
    }

.BrewersbyCompound {
    position: relative;
}

.brewerBuyersSection {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
}

.commonfeatures.brewerText {
    padding-top: 50px;
    padding-right: 40px;
}

.commonfeatures.buyersText {
    padding-top: 50px;
    padding-left: 40px;
}

.commonfeatures.brewerText h1 {
    font-size: 56px;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 800;
    color: #5a392f;
}

.commonfeatures.buyersText h1 {
    font-size: 56px;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 800;
    color: #414048;
}

.brewerPara {
    color: #5a392f;
    font-weight: 600;
    max-width: 330px;
    margin: 0 0 35px;
}

.learnMoreBtn a {
    background: #5A392F;
    color: #cd946d;
    padding: 8px 24px;
    margin-right: 25px;
    display: inline-block;
    font-size: 16px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 700;
    font-family: 'Libre Franklin', sans-serif;
    text-transform: uppercase;
}

.brewerPara span {
    font-weight: bold;
    font-family: 'Libre Franklin', sans-serif;
}

.commonfeatures.buyersText .brewerPara {
    color: #414048;
}

.commonfeatures.buyersText .learnMoreBtn a {
    background: #414048;
    color: #b6b6af;
}
/*End Brewers And Buyers Section*/
/*Fast Track Design*/
.col-md-6.fast-trackbgs, .col-md-6.fast-trackbgContent {
    padding: 0;
}

.fast-trackbgsimg img {
    width: 100%;
    min-height: 570px;
}

.setIcon img {
    height: 48px;
}

.fast-track {
    position: relative;
}

.connectionswithRstaurant {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
}

.iconsText {
    float: left;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 40px;
}

.iconsTextCombo {
    background: #ffffffed;
    float: left;
    width: 50%;
    padding: 15px 25px 10px;
}

    .iconsTextCombo h3 {
        color: #4d79c7;
        font-weight: 700;
        font-family: 'Libre Franklin', sans-serif;
        margin-bottom: 5px;
        font-size: 22px;
        margin-top: 15px;
    }

    .iconsTextCombo p {
        min-height: 115px;
        margin: 0;
    }

.fasttrackContents {
    color: #414048;
    padding-left: 40px;
    padding-top: 50px;
}

    .fasttrackContents h1 {
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 700;
        font-size: 42px;
        margin-bottom: 25px;
        margin-top: 0;
    }

    .fasttrackContents ul {
        padding: 0;
        margin: 0;
    }

        .fasttrackContents ul li {
            display: inline-block;
            width: 50%;
            float: left;
            background: url("../images/bluetick.png") no-repeat left;
            background-size: 20px;
            padding-left: 30px;
            margin-bottom: 15px;
            font-weight: 700;
            font-family: 'Libre Franklin', sans-serif;
        }

    .fasttrackContents .learnMoreBtn {
        clear: both;
        margin: 35px 0 0;
        float: left;
        width: 100%;
    }

        .fasttrackContents .learnMoreBtn a {
            background: #4d79c7;
            color: #fff;
        }
/*End Fast Track Design*/
/*Foddies Section*/
.distributionEmpowers {
    position: relative;
}

.col-md-6.distributionEmpowerstext {
    background: #e6c9b6;
    min-height: 450px;
    padding: 0;
}

.col-md-6.foodDrinkbg {
    padding: 0;
}

.foodiesimg img {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
}

.distributionEmpowersBg {
    color: #414048;
    padding-right: 40px;
    padding-top: 50px;
}

    .distributionEmpowersBg h1 {
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 700;
        font-size: 42px;
        margin-bottom: 25px;
    }

    .distributionEmpowersBg p span {
        font-weight: 700;
        font-family: 'Libre Franklin', sans-serif;
    }

.numericvalues {
    float: left;
    width: 100%;
}

.numbers {
    width: 50%;
    float: left;
    display: inline-block;
}

    .numbers h3 {
        font-family: 'Libre Franklin', sans-serif;
        font-size: 62px;
        font-weight: 800;
        margin-bottom: 2px;
    }

    .numbers span {
        font-size: 14px;
        font-family: 'Libre Franklin', sans-serif;
    }
/*End Foodies Section*/
/*Testimonial Slider*/
.testimonial_subtitle {
    color: #0aaa7a;
    font-size: 12px;
}

.testimonial_btn {
    background-color: #373d4b !important;
    color: #fff !important;
}

.seprator {
    height: 2px;
    width: 56px;
    background-color: #0aaa7a;
    margin: 7px 0 10px 0;
}

.raveheading {
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 700;
    font-size: 42px;
    margin-bottom: 35px;
    text-align: center;
}

.testimonialSection {
    display: none;
}

.testimonialContent {
    padding: 50px 0 80px;
}

    .testimonialContent .carousel {
        max-width: 1000px;
        margin: auto;
        padding: 0 35px;
    }

        .testimonialContent .carousel .control-prev.control-arrow:before {
            border-right: 8px solid #000;
        }

        .testimonialContent .carousel .control-next.control-arrow:before {
            border-left: 8px solid #000;
        }

        .testimonialContent .carousel .carousel-status {
            display: none;
        }

        .testimonialContent .carousel.carousel-slider .control-arrow:hover {
            background: transparent;
        }

    .testimonialContent .controls.testimonial_control .carousel-control {
        background-image: none;
        color: #000;
    }

    .testimonialContent .carousel-control {
        color: #000;
        text-align: center;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        opacity: 1;
        top: 80px;
    }

    .testimonialContent .carousel-inner p {
        font-family: 'Libre Franklin', sans-serif;
        font-size: 22px;
    }

    .testimonialContent .carousel-inner h4 {
        font-weight: 400;
        font-style: italic;
    }

    .testimonialContent .carousel-inner .item .img-responsive {
        border-radius: 10px;
        height: auto;
    }

    .testimonialContent .carousel-indicators li {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 0;
        background-color: #bcbdc0;
        border: 1px solid #bcbdc0;
    }

    .testimonialContent .carousel-indicators .active {
        width: 12px;
        height: 12px;
        margin: 0;
        background-color: #a1a2a5;
    }

    .testimonialContent .carousel-indicators {
        bottom: -30px;
        margin: auto;
        width: 100%;
        left: 0;
        right: 0;
    }
/*End Testimonials Slider*/
/*Footer Design*/
.footer {
    background: #5a392f;
    padding: 50px 0 20px;
}

.ftparas {
    color: #fff;
    font-size: 14px;
}

.ftrlogo {
    margin: 0 0 25px;
}

.footer ul.social-links li img {
    width: 32px;
}

.footer ul.social-links li {
    display: inline-block;
}

.ftrlogo img {
    width: 100px;
}

.footer-heading {
    color: #FFFFFF;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
}

.footer ul {
    padding: 0;
}

    .footer ul li {
        list-style: none;
        margin: 0 0 5px;
    }

.footer a {
    color: #fff;
    text-decoration: none;
}

.newsletter .elementor-heading-title {
    color: #FFFFFF;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5em;
}

.newsletter {
    text-align: center;
    padding: 45px 0 0;
}

    .newsletter p {
        text-align: center;
        color: #fff;
        font-weight: 400;
    }

.mc4wp-form-fields .mc_merge_var {
    position: relative;
    min-width: 270px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
}

.mc4wp-form-fields {
    position: relative;
    max-width: 558px;
    margin: auto;
}

    .mc4wp-form-fields .mc_merge_var input {
        width: 100%;
        height: 50px;
        background: #fff;
        border-color: transparent;
        padding: 15px;
        border-radius: 4px;
    }

.mc4wp-form .mc_merge_var input {
    background: #10172d;
    color: #454d65;
    font-weight: 400;
}

.mc_signup_submit {
    position: absolute;
    right: 0;
    top: 0;
}

    .mc_signup_submit input {
        background: #141d38;
        border-color: transparent;
        color: #fff;
        width: 117px;
        height: 50px;
    }

.ftr-copyright {
    border-top: none;
    background: #5a392f;
    padding: 25px 0;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.copyright p span a {
    color: #fff;
    text-decoration: none;
}
/*End Footer */
/*Brewerage Page*/
.simplyfying.Empowering .textSimply {
    position: absolute;
    top: 120px;
    padding-right: 25px;
}

.coming-fallbtn.anOrangeText {
    color: #F7941D;
    font-size: 20px;
}

.unlockThePotential {
    background: #e3dcd2;
    padding: 50px 0;
    font-size: 28px;
    font-weight: 400;
}

.unlockThePotentia p {
    margin: 0;
}

.OptionsForBrewers h1 {
    text-align: center;
    color: #414048;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 38px;
    font-weight: 800;
    margin: auto auto 35px;
}

.member-right img {
    height: 470px;
    object-fit: cover;
}

.pacakegBlock {
    background: #f0ede8;
    padding: 15px;
}

    .pacakegBlock h4 {
        text-align: center;
        font-size: 24px;
    }

    .pacakegBlock h1 {
        font-size: 24px;
        margin: 10px auto 10px;
        line-height: 33px;
    }

.pacakegBlocklists ol {
    padding: 0;
    margin-left: 15px;
    margin-bottom: 0;
}

    .pacakegBlocklists ol li {
        line-height: 25px;
    }

.pacakegBlock h1 span {
    font-size: 16px;
    font-weight: 600;
}

.OptionsForBrewers {
    padding: 50px 0;
}

.getreadybtn {
    text-align: center;
    margin: 40px auto 20px;
}

.packagesStory .getreadybtn {
    text-align: center;
    margin: 15px auto 15px;
}

.getreadybtn a {
    width: 180px;
    background: #414048;
    color: #f0ede8;
    padding: 6px 12px;
    border-radius: 6px;
    display: block;
    margin: auto;
    text-decoration: none;
}

.StreamlinedOnboarding {
    background: #e3dcd2;
    padding: 30px 0;
    color: #231f20;
}

.StreamlinedProcess h1 {
    font-weight: 800;
    font-size: 38px;
    margin-bottom: 30px;
}

.imgtags {
    width: 21%;
    float: left;
}

.StreamlinedProcess img.imgstrem {
    width: 100%;
}

.imgcontent {
    float: left;
    width: 79%;
    padding-left: 25px;
}

.StreamlinedProcess {
    float: left;
    width: 100%;
}

    .StreamlinedProcess h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 0;
    }

.StreamlinedOnboarding.patern2 {
    background: #f0ede8;
}

.WhyChooseBevport {
    padding: 50px 0;
}

    .WhyChooseBevport h2 {
        color: #946c60;
        text-align: center;
        font-weight: 800;
        font-size: 34px;
        margin-top: 0;
        margin-bottom: 35px;
    }

    .WhyChooseBevport.MakeMoneys h2 {
        color: #414048;
    }

    .WhyChooseBevport.MakeMoneys {
        padding-bottom: 0px;
    }

.bevfeartures {
    display: block;
    width: 20%;
    float: left;
    padding-right: 25px;
}

.featuresimg {
    text-align: center;
    margin: 25px 0 45px;
}

    .featuresimg img {
        height: 66px;
        width: auto;
    }

.mainFeatures p span {
    color: #946c60;
    font-weight: 700;
}

.mainFeatures p {
    line-height: 30px;
}

.jointhe {
    background: #f7941d url("../images/orangebg.png");
    background-repeat: repeat-x;
    padding: 50px 0 60px;
}

    .jointhe.buyerjoin {
        background: #4d79c7 url("../images/aboutbgd.png");
        background-repeat: repeat-x;
        padding: 50px 0 60px;
    }

    .jointhe h1 {
        font-weight: 800;
        color: #fff;
        text-align: left;
        font-size: 56px;
        margin-top: 0;
        margin-bottom: 35px;
    }

.regText {
    padding-right: 45px;
}

    .regText p {
        color: #fff;
        line-height: 33px;
        font-size: 22px;
        margin: 0 0 23px;
    }

.registraionDiv label {
    font-weight: 700;
}

.registraionDiv .form-group label {
    margin-bottom: 2px;
    line-height: 16px;
}

.registraionDiv {
    background: #f0ede891;
    padding: 20px 25px;
    min-height: 530px;
    position: relative;
}

.submitbtns .btn {
    background: #5a3a2f;
    border-color: #5a3a2f;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    border-radius: 8px;
}

    .submitbtns .btn:hover {
        background: #61473e;
        border-color: #61473e;
        color: #fff;
    }

    .submitbtns .btn:focus {
        outline: none;
    }

    .submitbtns .btn:focus-visible {
        outline: none;
        box-shadow: none;
    }

.registraionDiv .form-group .form-control {
    border-radius: 0;
}

.comingFallText {
    font-size: 28px;
    color: #fff;
    margin: 25px 0 0;
}

.faqSections.brewerspage {
    background: #e3dcd2;
    padding: 50px 0;
    font-size: 28px;
    font-weight: 400;
}

.faqSections h1 {
    text-align: center;
    color: #414048;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 38px;
    font-weight: 800;
    margin: auto auto 35px;
}

.accordianHead {
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: 'Libre Franklin' !important;
}

.accordianContent {
    font-size: 16px !important;
    font-family: 'Libre Franklin' !important;
}
/*End Brewerage Page*/
/*Buyers Page Design*/
.darkblacktext {
    color: #414048;
    display: block;
}

.col-md-5.simplecontent.buyermainhead {
    background-color: #b6b6af;
}

.textSimply.whitecolorhead h1 {
    color: #fff;
}

.beverageeffortless {
    background: #efefed;
    padding: 50px 0;
    font-weight: 400;
    font-size: 18px;
}

    .beverageeffortless p {
        margin: 0 0 20px;
    }

.streamlined-buyers .StreamlinedOnboarding {
    background: #fff;
    padding: 30px 0;
    color: #231f20;
}

    .streamlined-buyers .StreamlinedOnboarding.patern2 {
        background: #efefed;
    }

.streamlined-buyers .subtexs {
    margin: 0 0 30px;
}

.streamlined-buyers .StreamlinedProcess h1 {
    margin-bottom: 20px;
}

.orangeBold strong {
    color: #f7941d;
}

.StreamlinedProcess p {
    font-size: 18px;
}

.strive-revolutionize {
    background: #414048;
    color: #fff;
    padding: 25px 0;
}

    .strive-revolutionize p {
        margin: 0;
        font-size: 22px;
        font-weight: 600;
    }

.ExpectAsBevPortBuyer {
    padding: 50px 0;
}

    .ExpectAsBevPortBuyer h2 {
        color: #414048;
        text-align: center;
        font-weight: 800;
        font-size: 34px;
        margin-top: 0;
        margin-bottom: 35px;
    }

    .ExpectAsBevPortBuyer h3 {
        font-family: 'Libre Franklin';
        font-size: 18px;
        font-weight: 700;
        color: #414048;
    }

.bespoSubg {
    font-size: 18px;
    margin-bottom: 30px;
}

.befeaturesBlock {
    margin: auto;
    display: flex;
    justify-content: center;
}

.jointhe.buyerjoin .tabsbtns.active {
    background: #666;
    color: #fff;
}

.jointhe.buyerjoin .tabsbtns {
    background: #959595;
}

.jointhe.buyerjoin .registraionDiv {
    background: #c4c4c4cc;
}

.jointhe.buyerjoin .submitbtns .btn {
    background: #414049;
    border-color: #414049;
}

.jointhe.buyerjoin .tabsbtns:hover {
    border-color: #717171;
    background: #717171;
}

.jointhe.buyerjoin .submitbtns .btn:hover {
    border-color: #52515a;
    background: #52515a;
}

.faqSections.buyerspage {
    background: #efefed;
    padding: 50px 0;
    font-weight: 400;
    font-size: 18px;
}
/*End Buyers Page Design*/
/*About Page Design*/
.Persistence {
    background: #e4ebf7;
    padding: 50px 0;
}

.col-md-5.simplecontent.aboutmainBg {
    background-color: #4d79c7;
}


.Persistence h1 {
    font-weight: 800;
    font-size: 38px;
    margin-bottom: 30px;
    color: #231f20;
}

.muddtext .darkblacktext {
    display: block;
    color: #e2dcd1;
}

.textSimply.muddtext h1 {
    color: #231f20;
}

.textSimply.muddtext p {
    color: #fff;
}

.authorintro {
    font-size: 19px;
    margin: 0 0 15px;
}

.StoryofPassion {
    margin: 0 0 15px;
}

    .StoryofPassion:last-child {
        margin: 0;
    }

.partnersdiv {
    background: #d8e1f3;
    padding: 50px 0;
}

.partner-member img {
    width: 100%;
    max-width: 360px;
}

.partner-member.fisrtchild {
    padding-right: 40px;
}

.partner-member.secondchild {
    padding-left: 40px;
}

.partner-member .readMorebtn {
    display: none;
}

.partner-member h2 {
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 800;
    font-size: 40px;
    margin: 30px 0 10px;
}

.readMorebtn a {
    width: 170px;
    background: #4d79c7;
    color: #fff;
    padding: 6px 12px;
    border-radius: 6px;
    display: block;
    text-align: center;
    text-decoration: none;
}

.partner-member p {
    min-height: 148px;
}

.mediaimg {
    text-align: center;
}

    .mediaimg img {
        width: 100%;
        max-width: 400px;
    }

.mediaDiv h1 {
    color: #231f20;
    text-align: center;
    font-weight: 800;
    font-size: 54px;
    margin-top: 0;
    margin-bottom: 45px;
}

.mediaDiv {
    padding: 70px 0;
}

.row.havethreelogos {
    margin-top: 30px;
    margin-bottom: 30px;
}

    .row.havethreelogos .mediaimg img {
        max-width: 300px;
    }
/*End About Page Design*/
/*Loeder Css*/
.loadercompnen {
    width: 150px;
    height: 150px;
    position: relative;
    margin: auto;
}

.loaderbfg {
    position: fixed;
    background: #0000009e;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    top: 0;
    z-index: 9999;
    left: 0;
}

.spinner-border {
    display: inline-block;
    width: 150px;
    height: 150px;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    color: #b6b6af;
}

.loaderimg {
    width: 150px;
    height: 150px;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
}

    .loaderimg img {
        width: 80px;
    }

.vissibleHide .loaderbfg {
    position: absolute;
    overflow: hidden;
    top: 70px;
    background: #0c0c0c;
    left: 0;
    height: calc(100vh - 130px);
}

.vissibleHide .loaderimg img {
    width: 80px;
    background: #fff;
    padding: 5px;
}

.vissibleHide .loadercompnen {
    top: 0px;
}

.rightContetBlock.vissibleHide {
    overflow: hidden;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

/*End Loader Css*/
/*Resources Page*/
.outerlinksboundary {
    padding: 50px 0;
    background: #d8e1f3;
}

.linksoutdiv {
    background: #4d79c7;
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 800;
    box-shadow: 0px 15px 10px -15px #111;
    max-width: 270px;
    margin: auto;
}

    .linksoutdiv a {
        color: #fff;
        text-decoration: none;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        min-height: 90px;
    }

        .linksoutdiv a:hover {
            box-shadow: 0 0 20px #00000087;
            border-radius: 6px;
        }

#pricehoneWeb {
    background: #ededeb;
    padding: 30px 0 50px;
}

    #pricehoneWeb .card-heading.topsubheading {
        color: #414048;
        text-align: center;
        font-weight: 800;
        font-size: 34px;
        margin-top: 0;
        margin-bottom: 35px;
    }

.pricecalPara p {
    font-size: 18px;
}

.pricecalPara {
    margin-bottom: 30px;
}

.customToolTip i {
    cursor: pointer;
}

.mainpageFilter {
    margin: 0 0 20px;
}

    .mainpageFilter label {
        font-size: 16px;
        font-weight: 600;
    }

.priceLivepage .priceCalculator .form-control {
    border-radius: 0;
    max-width: 100%;
    width: 100%;
}

.priceLivepage .priceCalculator .table {
    background: #fff;
}
/*End Resources Page*/
/*Terms of Use*/
.termsofusespara {
    background: #fff;
    padding: 50px 0;
}

.termmainPart a {
    color: #4d79c7;
}

.simplyfying.Empowering.TermsofuSep .simplecontentimg img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.simplyfying.Empowering.TermsofuSep .manImg img {
    width: 100%;
    height: 400px;
}

.termmainPart p {
    margin: 0 0 15px;
    text-align: justify;
    font-weight: 400;
}
/*End Terms of Use*/
/*Contact Page*/
.formcnt .form-group .form-control {
    border-radius: 0;
}

.contactbar {
    padding: 55px 0;
    background: #fff;
}

.contactinfo h5 {
    margin: 0 0 8px;
    font-weight: 800;
    font-family: 'Libre Franklin', sans-serif;
}

.contactbar h3 {
    margin: 0 0 15px;
    font-weight: 800;
    font-size: 38px;
    font-family: 'Libre Franklin', sans-serif;
}

.contactinfo h4 {
    margin: 20px 0 5px;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Libre Franklin', sans-serif;
}

.formcnt {
    padding: 0;
}

    .formcnt p {
        margin: 0 0 25px;
    }

    .formcnt .submitbtns .btn {
        background: #4d79c7;
        border-color: #4d79c7;
        border-radius: 8px;
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        margin-top: 15px;
    }

.red-tick {
    color: red;
}

.formcnt label {
    font-weight: 700;
}

.form-control.error {
    border: 1px solid red;
}

.selectTodesign.error > div {
    border-color: red;
}

.contactpages .col-md-5.simplecontent.aboutmainBg .simplecontentimg img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.simplyfying.Empowering.contactpages .manImg img {
    height: 400px;
    object-fit: cover;
}
/*End Contact Page*/
/*404 Page*/
.sectionNotFound {
    padding: 55px 0;
    background: #e4ebf7;
}

.notfound {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

    .notfound h2 {
        margin: 0;
        font-weight: 800;
    }

    .notfound h1 {
        font-weight: 800;
        font-size: 44px;
        margin: 10px 0;
    }

.btntakehome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 360px;
    width: 100%;
    margin-top: 10px;
}

    .btntakehome a {
        width: 170px;
        display: inline-block;
        text-align: center;
        padding: 6px 12px;
        background: #4d79c7;
        color: #fff;
        text-decoration: none;
        border-radius: 6px;
    }
/*End 404 Page*/
/*Error */
.error-message {
    position: absolute;
    font-size: 12px;
    color: red;
}
/*Error*/
/*Brewers Form Design*/
.formtab-view {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.formactions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tabsbtns {
    min-width: 126px;
    width: 100%;
    text-align: center;
    padding: 8px 6px;
    background: #846256;
    cursor: pointer;
    color: #fff;
    border-right: 1px dashed #a98b81;
}

    .tabsbtns:last-child {
        border: none;
    }

    .tabsbtns.active {
        background: #5a3a2f;
        color: #fff;
    }

    .tabsbtns:hover {
        background: #7d594c;
    }

.registraionDiv .submitbtns {
    margin: 20px 0 0px;
    position: absolute;
    bottom: 25px;
}

.remebercheck a {
    color: #1185d5;
    text-decoration: none;
}

.remebercheck input {
    margin: 0;
    height: 14px;
    width: 14px;
    margin-right: 10px;
}
/*End Brewers Form Design*/
/*Error */
.error-message {
    position: absolute;
    font-size: 12px;
    color: red;
    line-height: 12px;
}

.registraionDiv .form-group .form-control {
    margin-bottom: 2px;
}
/*Error*/
.JoinTheNew.buyersreg .jointhe {
    background: #b6b6af url("../images/buyerstopbase.png");
    background-repeat: repeat-x;
    padding: 70px 0;
}

    .JoinTheNew.buyersreg .jointhe h1 {
        color: #414048;
    }

.JoinTheNew.buyersreg .regText p {
    color: #414048;
}
/*Login Page*/
.loginformdesign {
    margin: auto;
    padding: 0;
    width: 100%;
    text-align: left;
}

.col-md-5.manBgimg {
    padding: 0;
}

.loginformdesign .form-control {
    border-radius: 0;
}

.loginformdesign .form-group .form-control {
    margin-bottom: 2px;
}

.loginformdesign h3 {
    margin: 0px 0 25px;
    text-align: left;
    font-weight: 800;
    font-size: 50px;
}

    .loginformdesign h3 span {
        color: #4d79c7;
    }

.loginformdesign p {
    text-align: left;
    margin: 0 0 25px;
}

.loginformdesign label {
    font-weight: 700;
}

.simplyfying.Empowering .textSimply.loginForms {
    top: 90px;
}

.showhidepassowrd {
    position: relative;
}

.eyeslash {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #1185d5;
}

.logibtns button {
    width: 100%;
    background-color: #4d79c7;
    border: 1px solid #4d79c7;
    color: #fff;
    border-radius: 8px;
    box-shadow: none;
    margin: 10px auto 0;
    max-width: 160px;
    padding: 8px;
    text-transform: uppercase;
}

.logibtns {
    text-align: center;
}

.remeberAndForgot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: -10px;
    position: relative;
}

.remebercheck label {
    margin: 0;
    font-size: 14px;
    padding-left: 0;
    color: #1185d5;
    line-height: 16px;
    position: relative;
    top: 0px;
    font-weight: 500;
}

.forgotpswd a {
    font-size: 14px;
    color: #1185d5;
    text-decoration: none;
}

.createAccount {
    text-align: center;
    margin: 15px 0 0;
}

    .createAccount a {
        text-decoration: none;
        color: #1185d5;
        font-size: 14px;
    }

.col-md-7.manBgimg.logform {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 608px;
    background: #d7d7d3 url("../images/loginLeaves.png") no-repeat;
    background-size: cover;
}

.createAccount h3 {
    font-weight: 800;
    color: #231f20;
    font-size: 28px;
    margin-top: 0;
}

.buttonbrownTwo.lgoinpages a {
    margin: 0;
    text-decoration: none;
}

.buttonbrownTwo.lgoinpages {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

    .buttonbrownTwo.lgoinpages .buyersLink {
        background: #b6b6af;
    }

.loginformdesign .form-group {
    margin-bottom: 20px;
}
/*End Login Page*/
/*Privacy Page*/
.simplyfying.Empowering.privacy-policy .simplecontentimg img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.simplyfying.Empowering.privacy-policy .manImg img {
    width: 100%;
    height: 400px;
}
/*End Privacy Page*/
/*Registration Page Desgin*/
.col-md-5.simplecontent.registrationPg {
    background: #5a392f;
}

.registraionPages .col-md-5.simplecontent.registrationPg .simplecontentimg img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.simplyfying.Empowering.registraionPages .manImg img {
    height: 400px;
    object-fit: cover;
}

.regiterComponent .form-group .form-control {
    border-radius: 0;
}

.regiterComponent {
    padding: 55px 0;
}

    .regiterComponent.colorshade {
        background: #f4f0ef;
    }

.form-headings h2 {
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 26px;
}

.form-headings .error-message {
    top: 35px;
}

.form-headings {
    position: relative;
    margin-bottom: 45px;
}

.regiterComponent .form-group {
    margin-bottom: 20px;
}

.regitrationBtns button {
    background-color: #4d79c7;
    border: 1px solid #4d79c7;
    border-radius: 8px;
    box-shadow: none;
    color: #fff;
    margin: 10px auto 0;
    max-width: 140px;
    padding: 8px;
    text-transform: uppercase;
    width: 100%;
}

    .regitrationBtns button.loginb {
        margin-right: 30px;
    }

        .regitrationBtns button.loginb.resetFields {
            background: #fff;
            color: #4d79c7;
        }

.form-check-label {
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 16px;
}

.col-md-5.simplecontent.registrationPg.buyrersbg {
    background: #b6b6af;
}

.regiterComponent.colorshadebuyer {
    background: #efefed;
}
/*End Registration Page*/
/*Press Release*/
.press-release-listing {
    padding: 50px 0;
}

.pressCards {
    box-shadow: 0 15px 15px -15px #4c4c4c;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    background: #4d79c7;
    position: relative;
}

    .pressCards a {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 15px;
        text-decoration: none;
        color: #fff;
    }

        .pressCards a h3 {
            margin: auto;
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
        }

.releaseDate {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    font-style: normal;
    color: #cfe0ff;
}

.pressCards:hover {
    box-shadow: 0 0 10px #4c4c4cb8;
}

.press-head h1 {
    font-weight: 800;
    font-size: 38px;
    margin-bottom: 30px;
    color: #231f20;
}

.craft-head h2 {
    font: 300 33px/40px 'Open Sans', sans-serif;
    color: #cfd8ee;
    margin: 0 0 23px;
}

.craft-head h3 {
    font: 13px/20px 'Open Sans', sans-serif;
    color: #e7efff;
    margin: 0 0 6px;
}

.craft-head p {
    margin-bottom: 24px;
    font: 13px/20px 'Open Sans', sans-serif;
}

.craft-head hr {
    background-color: #51596d;
    border: none;
    height: 1px;
    margin: 0 0 21px;
}

.craft-head .contact {
    padding: 63px 85px 50px 80px;
    color: #8391ab;
    background-color: #14163D;
    margin: 20px auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.prefix {
    width: auto;
    display: inline-block;
}

.email {
    color: #ffffff;
    margin-left: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

    .email:hover {
        color: #cfd8ee;
        text-decoration: none;
    }

.list-issues li b {
    line-height: 35px;
}

.issue-area {
    margin: 30px 0;
}
/*End Press Release*/
/*Tolltip*/
.customToolTip {
    position: relative;
}

    .customToolTip i {
        cursor: pointer;
    }

    .customToolTip p {
        position: absolute;
        background: #414048;
        color: #fff;
        font-size: 13px;
        font-weight: normal;
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0 0 10px #00000085;
        top: -10px;
        min-width: 240px;
        left: 30px;
        z-index: 99;
    }

        .customToolTip p::before {
            content: "\f0d9";
            font-family: FontAwesome;
            position: absolute;
            left: -8px;
            color: #414048;
            font-size: 24px;
            top: -2px;
        }

        .customToolTip p.showTooltip {
            display: block;
        }
/*End Tolltip*/
/*Join the Revolution*/
.joinrevdivision {
    padding: 55px 0;
}
/*End Join the Revolution*/
/*Media Query*/
@media only screen and (max-width:1400px) {
    .col-md-6.buyersPart .buyerbg img, .col-md-6.brewersPart .brewersBg img {
        max-height: 100%;
        width: 100%;
        height: 770px;
    }
}

@media only screen and (max-width:1200px) {
    .manImg img {
        object-fit: cover;
    }

    .col-md-6.buyersPart .buyerbg img, .col-md-6.brewersPart .brewersBg img {
        max-height: 100%;
        width: 100%;
        height: 770px;
    }

    .fast-trackbgsimg img {
        width: 100%;
        min-height: 670px;
    }

    .foodiesimg img {
        width: 100%;
        max-height: auto;
        object-fit: cover;
        height: 450px;
    }

    .numbers h3 {
        font-size: 50px;
    }

    .numbers span {
        font-size: 12px;
    }

    .textSimply h1 {
        font-size: 50px;
    }

    .tabsbtns {
        min-width: auto;
    }

    .row.homePageToops .textSimply {
        top: 10px;
    }

    .main-naivgation ul.nav.navbar-nav {
        max-width: 100%;
    }

    .makebtnright a {
        padding: 8px;
        border-radius: 8px;
        font-size: 14px;
    }

    .navbar-collapse.main-menu-nav.mainsubmenu .nav > li > a {
        padding: 10px;
    }

    .iconsTextCombo {
        min-height: 300px;
    }
}

@media only screen and (max-width:992px) {
    .row.upcontent {
        display: flex;
        flex-direction: column;
    }

    .col-md-5.simplecontent {
        background-color: #5a392f;
        height: 100%;
        padding: 0;
        width: 100%;
        order: 2;
        display: none;
    }

    .col-md-7.manBgimg {
        padding: 0;
        width: 100%;
        order: 1;
    }

    .moveTopUp {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: #5a392f url("../images/homecnavs.png");
        margin: 0;
        clear: both;
        background-size: 100%;
    }

        .moveTopUp .col-md-4 {
            width: 100%;
        }

    .manImg img {
        width: 100%;
        height: auto;
    }

    .textSimply {
        position: relative;
        top: 0;
        padding: 50px 0;
    }

    .newdistribution h1 {
        max-width: 600px;
        text-align: center;
        margin: 50px auto;
    }

    .row.videorows .col-md-4.col-sm-12 {
        width: 100%;
    }

    .row.videorows .col-md-8.col-sm-12 {
        width: 100%;
        margin: auto;
        text-align: center;
    }

        .row.videorows .col-md-8.col-sm-12 .videoside {
            text-align: left;
        }

    .video-container video {
        width: 100%;
        height: auto;
        margin: 0 0 25px;
    }

    .buttonbrownTwo {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .row.hidethis {
        display: none;
    }

    .brewerBuyersSection {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
    }

        .brewerBuyersSection .container {
            margin: 0;
            width: 100%;
        }

    .col-md-6.brewersbake {
        width: 100%;
        background: #cd946d;
        padding: 0 25px;
    }

    .commonfeatures.brewerText {
        padding-top: 50px;
        padding-right: 0;
        padding-bottom: 50px;
    }

    .brewerPara {
        max-width: 100%;
    }

    .brewersimg img {
        height: auto;
        width: 100%;
    }

    .col-md-6.byersbake {
        background: #b6b6af;
        width: 100%;
        padding: 0 25px;
    }

    .commonfeatures.buyersText {
        padding-top: 50px;
        padding-left: 0;
        padding-bottom: 50px;
    }

    .row.keepimg {
        display: none;
    }

    .row.keepcontent {
        display: flex;
        flex-direction: column;
    }

    .col-md-6.order2 {
        width: 100%;
        order: 2;
        background: url("../images/fastrackbg.jpg") no-repeat;
        background-size: cover;
    }

    .col-md-6.order1 {
        order: 1;
        width: 100%;
    }

    .connectionswithRstaurant {
        position: relative;
    }

    .yourConnections .container-fluid {
        padding: 0;
    }

    .distributionEmpowers {
        position: relative;
        margin: 35px 0 0;
    }

    .col-md-6.distributionEmpowerstext {
        display: none;
    }

    .distributionEmpowers .connectionswithRstaurant {
        position: relative;
    }

    .distributionEmpowers {
        position: relative;
        margin: 35px 0 0;
        background: #e6c9b6;
    }

    .distributionEmpowersBg {
        color: #414048;
        padding-right: 0;
    }

    .distributionEmpowers {
        position: relative;
        margin: 35px 0 0;
        background: #e6c9b6;
        padding: 0 0 50px;
    }

    .distributionEmpowersBg h1 {
        margin-top: 0;
    }

    .fasttrackContents {
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    .iconsText {
        padding: 40px;
    }

    .testimonialContent .col-sm-2 {
        width: 100%;
        text-align: center;
        margin: 0 0 35px;
    }

        .testimonialContent .col-sm-2 img {
            margin: auto;
        }

    .testimonialContent .col-sm-10 {
        width: 100%;
        max-width: 600px;
        margin: auto;
        text-align: center;
        float: none;
    }

    .footer .col-md-3 {
        width: 100%;
        text-align: center;
        margin: 0 0 35px;
    }

    .footer ul {
        padding: 0;
    }

        .footer ul.social-links {
            margin-top: 25px;
        }

    .ftr-copyright {
        padding: 5px 0 20px;
    }

    .footer {
        padding: 50px 0 0;
    }

    .makebtnright {
        z-index: 1;
        position: relative;
    }

        .makebtnright a {
            padding: 8px 15px;
            text-decoration: none;
        }

    .simplyfying.Empowering .textSimply {
        position: relative;
        top: 0;
        padding-right: 25px;
    }

    .packagesStory .col-md-6 {
        width: 50%;
        float: left;
    }

    .packagesStory {
        margin: 0 0 35px;
    }

    .member-right img {
        width: 100%;
        height: auto;
    }

    .regText {
        display: flex;
        flex-direction: column;
        padding-right: 0;
    }

    .regImg {
        order: 1;
        margin: 0 0 35px;
    }

    .regText p {
        order: 2;
    }

    .JoinTheNew .regText p {
        order: 0;
    }

    .JoinTheNew .comingFallText {
        margin: 25px 0 10px;
        text-align: center;
    }

    .jointhe {
        background-image: none;
    }

        .jointhe h1 {
            font-size: 56px;
            text-align: center;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

    .submitbtns {
        text-align: center;
    }

    .imgtags {
        width: 28%;
    }

    .imgcontent {
        width: 72%;
    }

    .moveTopUp.Revolutionizing {
        background: #b6b6af url("../images/buyerstopbase.png");
    }

    .moveTopUp.aboutheadphone {
        background: #4d79c7 url("../images/aboutbgd.png");
    }

    .partner-member h2 {
        font-size: 52px;
    }

    .partner-member.fisrtchild {
        padding-right: 0;
    }

    .partner-member.secondchild {
        padding-left: 0;
        margin-top: 50px;
    }

    .row.toplines .col-md-3 {
        width: 25%;
        float: left;
    }

    .row.havethreelogos .col-md-4 {
        width: 33.3%;
        float: left;
    }

    .row.threerow .col-md-3 {
        float: left;
        width: 25%;
    }

    .mainsubmenu .navbar-nav > li > a {
        padding: 10px 8px;
    }

    .makebtnright {
        height: 72px;
    }

    .main-naivgation .navbar-brand img {
        height: 60px;
    }

    .main-naivgation .navbar-brand {
        padding: 5px 30px;
        padding-left: 0;
        padding-right: 0;
    }

    .main-naivgation .navbar {
        min-height: auto;
    }

    .mainsubmenu {
        padding: 0;
    }

    .linksoutdiv {
        margin: 0 0 25px;
    }

    .registraionDiv {
        min-height: 590px;
    }

    .col-md-7.manBgimg.logform {
        display: none;
    }

    .moveTopUp.loginBAoutphone {
        background: #b6b6af url("../images/buyerstopbase.png");
    }

    .col-md-5.manBgimg {
        width: 100%;
        order: 1;
    }

    .moveTopUp .col-md-6 {
        width: 100%;
    }

    .simplyfying.Empowering .textSimply.loginForms {
        top: 0;
    }

    .linksoutdiv {
        margin: 0 auto 25px;
    }

    .row.homePageToops .textSimply {
        top: 0;
        position: relative;
    }

    .main-naivgation ul.nav.navbar-nav {
        padding: 15px 0;
        left: -80px;
    }

    .mainsubmenu .navbar-nav > li {
        font-size: 13px;
    }

    .makebtnright a {
        font-size: 13px;
    }

    .iconsTextCombo {
        min-height: 230px;
    }

    .navbar-header {
        z-index: 1;
        position: relative;
    }

    .mediaimg {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:767px) {
    .mainsubmenu .navbar-nav > li {
        display: block;
    }

    .main-naivgation ul.nav.navbar-nav {
        position: relative;
        left: 0;
    }

    .makebtnright {
        height: auto;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
    }

    .main-naivgation .navbar {
        padding-bottom: 0px;
    }

    .bevfeartures:last-child {
        margin: 0;
        border: none;
    }

    .bevfeartures {
        display: block;
        width: 100%;
        float: none;
        padding: 0 15px;
        margin: 0 0 35px;
        border-bottom: 1px dashed #ccc;
    }

    .befeaturesBlock {
        display: block;
    }

    .ExpectAsBevPortBuyer h3 {
        text-align: center;
    }

    .main-naivgation .navbar-brand {
        padding-left: 15px;
    }

    .main-naivgation .navbar-default .navbar-toggle {
        margin-right: 0;
    }

    .makebtnright {
        padding-bottom: 25px;
    }
}

@media only screen and (max-width:600px) {
    .iconsText {
        padding: 20px 0px;
    }

    .numbers h3 {
        font-size: 48px;
    }

    .numbers span {
        font-size: 12px;
    }

    .fasttrackContents {
        padding-left: 0;
        padding-bottom: 30px;
    }

        .fasttrackContents ul li {
            background-size: 16px;
            padding-left: 25px;
            font-size: 14px;
        }

        .fasttrackContents .learnMoreBtn {
            margin: 20px 0 0;
        }

    .iconsTextCombo {
        padding: 15px 15px 10px;
    }

    .packagesStory .col-md-6 {
        width: 100%;
        float: left;
    }

    .pacakegBlock {
        max-width: 282px;
        margin: auto auto 30px;
        width: 100%;
    }

    .strive-revolutionize p {
        font-size: 20px;
    }

    .tabsbtns {
        min-width: auto;
        min-height: 62px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .helloAreas {
        margin: 0 0 15px;
    }

    .billingImforArea h3 {
        margin: 0 0 5px !important;
    }

    .billingImforArea.billadd {
        margin: 0 0 25px;
    }

    .billingImforArea.qr-code {
        position: relative;
        right: 0;
    }

        .billingImforArea.qr-code.order-qr {
            position: relative;
            left: 0;
        }

    .billingImforArea.del-methos {
        margin: 25px 0 0;
    }

    .wizardShowOnly.secinfs {
        padding: 0;
    }

    .row.informationBiligns .col-md-6 {
        width: 60%;
    }

    .row.informationBiligns {
        margin-top: 35px;
        position: relative;
    }

    .col-md-3.calladressr {
        width: 60%;
    }

    .col-md-3.haveqrs {
        position: absolute;
        top: 0;
        right: 0;
    }

    .row.puchaseHistoryTable .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }

    .billingImforArea.billadd .callAdress.samllwidth p {
        width: auto;
    }

    .row.orderHeader .orderIdText {
        text-align: left;
    }

    .row.invoiceHeader .col-md-6 {
        width: 50%;
        float: left;
    }
}

@media only screen and (max-width:480px) {
    .textSimply h1 {
        font-size: 42px;
    }

    .newdistribution h1 {
        font-size: 42px;
    }

    .buttonbrownTwo a {
        padding: 8px 10px;
        margin-right: 10px;
    }

    .fasttrackContents h1 {
        font-size: 32px;
    }

    .fasttrackContents ul li {
        width: 100%;
    }

    .iconsTextCombo {
        width: 100%;
    }

    .distributionEmpowersBg h1 {
        font-size: 32px;
    }

    .numbers {
        width: 100%;
    }

    .jointhe h1 {
        font-size: 46px;
    }

    .imgtags {
        width: 100%;
        text-align: center;
        float: none;
    }

    .imgcontent {
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
    }

    .strive-revolutionize p {
        font-size: 18px;
    }

    .mediaDiv h1 {
        font-size: 36px;
    }

    .row.toplines .col-md-3 {
        padding: 0 10px;
    }

    .row.havethreelogos .col-md-4 {
        padding: 0 10px;
    }

    .row.threerow .col-md-3 {
        padding: 0 10px;
    }

    .mediaDiv {
        padding: 50px 0;
    }

    .billingImforArea.qr-code.order-qr canvas {
        height: 100px !important;
        width: 100px !important;
    }
}

.brewer-message {
    position: absolute;
    font-size: 12px;
    color: #4a5dc1;
    line-height: 12px;
    top: 35px;
}

.brewer-Label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    color: #4a5dc1;
}
/*End Media Query*/


/*// show boostrap dropdown menu on hover */
.dropdown:hover .dropdown-menu {
    display: block;
}


/*// blog page*/
.col-md-5.simplecontent.blogmainBg {
    background-color: #65c7c9;
}


.moveTopUp.blogheadphone {
    background: #65c7c9 url("../images/aboutbgd.png");
}


/*// eye ball icon within button viewAsBuyerButton*/
.viewAsBuyerButton svg {
	font-size: 18px !important;
	transform: translate(-3px, 2px) !important;
}


/*// view buyer button tool tip */
	.tooltiptext_viewasBuyer {
		visibility: hidden;
		width: 215px;
		background-color: black;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 1px;

		margin-right:5px;
		/* Position the tooltip */
	/*	position: absolute;*/
		z-index: 1;
	
	}

.tooltip_viewasBuyer:hover .tooltiptext_viewasBuyer {
	visibility: visible;
}

/*// view as buyer button brewer top right corner ---> viewAsBuyerButton*/
.viewAsBuyerButton {
	background: #3636bd;
	color: #fff;
	padding: 8px 24px;
	margin-right: 25px;
	display: inline-block;
	font-size: 16px;
	border-radius: 8px;
	text-decoration: none;
	font-weight: 700;
	font-family: 'Libre Franklin', sans-serif;
	text-transform: uppercase;
	border: 1px solid;
	border-color: black;
	cursor: pointer;
}


/*///---------------Retail List Exort Button------------------*/
.viewRetailListButton {
	background: #ff9027;
	color: #fff;
	padding: 8px 24px;
	margin-right: 25px;
	display: inline-block;
	font-size: 16px;
	border-radius: 8px;
	text-decoration: none;
	font-weight: 700;
	font-family: 'Libre Franklin', sans-serif;
	text-transform: uppercase;
	border: 1px solid;
	border-color: black;
	cursor: pointer;
}

/*---svg image within button mui---*/
.viewRetailListButton svg {
	font-size: 18px !important;
	transform: translate(-3px, 2px) !important;
}

/*///--------------- end Retail List Exort Button------------------*/


.costComparisonBtn {
	background: #5A392F;
	color: #fff;
	padding: 8px 24px;
	margin-right: 25px;
	display: inline-block;
	font-size: 16px;
	border-radius: 8px;
	text-decoration: none;
	font-weight: 700;
	font-family: 'Libre Franklin', sans-serif;
	text-transform: uppercase;
	border: 1px solid;
	border-color: black;
}


.costComparisonFOBBtn {
	background: #F7941D;
	color: #14136f;
	/*padding: 8px 24px;
	margin-right: 25px;
	display: inline-block;
	font-size: 16px;*/
	border-radius: 8px;
	text-decoration: none;
	/*font-weight: 700;*/
	font-family: 'Libre Franklin', sans-serif;
	/*text-transform: uppercase;*/
	border: 1px solid;
	border-color: black
}




.corners1 {
	border-radius: 25px;
	background: #874835;
	padding: 20px;
	margin-bottom: 10px;
/*	#874835 
		#843c0c*/
}

.saleServiceBackGround {
	background-image: url(./../../assets/images/simply.png);
	background-color: #cd946d;
	/*background-repeat:no-repeat;*/
	/*width: 10px;
	height: 20px;*/
}


.SalesServicesBtn {
	padding: 10px 30px;
	/*display: block;*/
	background: #5a392f;
	color: #fff;
	text-align: center;
	font-weight: 600;
	line-height: 19px;
	max-width: 225px;
	text-decoration: none;
	border-radius: 8px;
	width: 100%;
}


.blogCorners {
	border-radius: 25px;
	background: #ffad01;
	padding: 20px;
	margin-bottom: 10px;
	border: 1px solid #333131;
}



